<template>
    <header class="bg-white shadow">
        <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 class="text-3xl font-bold tracking-tight text-gray-900 uppercase">Accounts</h1>
        </div>
    </header>
    <main>


        <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-xl ">
            <!-- Your content -->
            <div class="px-4 sm:px-6 lg:px-8">
                <!-- <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <h1 class="text-base font-semibold leading-6 text-gray-900">Transactions</h1>
              <p class="mt-2 text-sm text-gray-700">A table of placeholder stock market data that does not make any sense.</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
            </div>
          </div> -->
                <div class="mt-8 flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Prénom</th>
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Nom
                                            </th>
                                
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Email
                                        </th>
                                       
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="(item, index) in store.state.accounts.data">
                                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{
                                            item.first_name }}</td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">
                                            {{ item.last_name
                                            }}</td>
                                    
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.email }}</td>
                                   
                                    </tr>

                                    <!-- More transactions... -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>
</template>
<script setup>
import { onMounted } from 'vue';
import store from '../store';


onMounted(() => {
    store.dispatch('getAccounts');
})



</script>