<template>



  <main>

<!-- partie 2 -->
  <div class=" py-10">
    <div>
      <div>
        <div class="bg-white py-24 sm:py-5">
        <div>
          <img src="/assets/img/img/img3.jpg" alt="thetiptop" class=" object-coover h-20 w-full object-cover">
        </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-5">
    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
      <!-- <p class="text-base font-semibold leading-7 text-indigo-600">Deploy faster</p> -->
      <!-- <h1 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Règlement du jeu</h1> -->
      <div class="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-700 lg:max-w-none lg:grid-cols-2">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-[#00587E] text-4xl">Réglement du jeu-concours</h2>
          <br>
          <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">1. Organisateur</h2>
          <p>Le présent jeu-concours est organisé par Thétiptop, domicilié(e) 18 rue Léon Frit, 75011 Paris, représenté(e) par Mr Eric Bourdon, Gérant, désigné ci-après « l’Organisateur ». Le règlement du présent jeu-concours est déposé en l’étude de Maître Arnaud Rick sise 5 rue de Morini, 75020 Paris.</p>
           <br>
           <div>
            
           </div>
        <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">2. Lots</h2>
          <ul class="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
            <p>Les participants ont la possibilité de gagner les lots suivants :</p>
              <li>
                900 000 x Infuseur à thé ;
              </li>
              <li>
                300 000 x Boite 100 g thé détox ou infusion;
              </li>
              <li>
                150 000 x Boite 100 g thé signature;
              </li>
              <li>
                90 000 x Cofret découverte d’une valeur de 39 euros;
              </li>
              <li>
                60 000 x Cofret découverte d’une valeur de 69 euros;
              </li>
              <li>
                1 x 1 an de thé d’une valeur de 360 euros.
              </li>
          </ul>
          <p>La remise des lots n’impliquera aucun frais pour les participants.</p>
          <p>La remise du lot ne pourra pas être effectuée sous forme de somme d’argent.</p>
          <p>Le participant se voyant attribuer un lot aura l’obligation de s’acquitter des taxes et impôts y afférant.</p>
          <br>
          <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">3.Conditions de participation</h2>
        <p>La participation au présent jeu-concours est réservée aux personnes remplissant les conditions suivantes :</p>
         <p>Le jeu concours est ouvert à toute personne majeure</p>  

         <br>
         <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">4.Frais de participation</h2>
         <p>La participation au tirage au sort est subordonnée à l’achat d’un minimum de 49 euros de nos produits.</p>
        </div>

        <div>
          <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">5.Modalités de participation</h2>
        <p>La participation au jeu-concours est ouverte à compter du 01/07/2023 au 30/07/2023.</p>
        <p>Chaque personne pourra participer autant de fois qu’elle le souhaite.</p>
        <p>La participation se déroule selon les modalités suivantes :</p>
        <p>Pour participer au concours, le client doit s’inscrire obligatoirement sur le site www.thetiptop.click puis vérifier grâce à son numéro de 10 chiffres disponibles sur son ticket le type de lots qu’il a gagné.</p>
     
          <br>
          <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">6.Modalités de participation</h2>
           <p>Les gagnants seront déterminés de la manière suivante :</p>
           <p>Après votre achat et votre inscription et vérification sur le site www.thetiptop.click, vous recevrez un message pour récupérer votre lot en boutique ou le demander en ligne</p>
             <br>
             <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">7.Date et modalités de publication des résultats</h2>
              <p>Les résultats seront communiqués le 30/08/2023. Les gagnants seront contactés directement par l’Organisateur.</p>
              <br>
              <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">8.Données personnelles</h2>
               <p>Des données à caractère personnel concernant les participants sont collectées avec leur consentement dans le cadre de ce jeu-concours dans le but d’établir l’identité du participant, de le prévenir en cas de gain et de lui remettre le lot lui étant attribué.</p>
               <p>Les données seront traitées conformément aux dispositions de notre politique de confidentialité pouvant être consultée à l’adresse suivante : https://thetiptop.click/politique-de-confidentialite/.</p>
               <br>
               <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white">9.Communication du règlement</h2>
                <p>Le présent règlement est mis à disposition de toute personne en faisant la demande. Il est consultable en l’étude de Maître Arnaud Rick, sise 5 rue de Morini, 75020 Paris. Il peut être consulté préalablement à la participation au jeu-concours et indépendamment de tout achat. La participation au jeu-concours suppose l’acceptation sans réserve du présent règlement.</p>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
      <div >

      </div>
    </div>
  </div>

    </main>
</template>

<script>

export default {
  name: 'Dotations',
  metaInfo: {
    title: 'Dotations du concours',
    meta: [
      { name: 'description', content: 'Vous trouverez ici la liste des dotations du jeu concours à gagner' },
      { property: 'og:title', content: 'Dotations du concours' },
    ],
    link: [
    {rel: 'canonical', href: 'https://thetiptop.click/dotations'}
  ]
  },
};

</script>