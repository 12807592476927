import apiService, { kCheckTicketApi, kTicketCollectedApi, kTicketDrawApi, kTicketMarkDrawApi, kTicketsApi, kGetFinalDrawApi } from '../services/apiservice';
import { useToast } from 'vue-toast-notification';
const $toast = useToast();
export default {
    state: {
        data: [],
        final_draw_data: {},
        loading: false,
        error: null
    },
    getters: {
    },
    actions: {
        async getTickets({ commit }) {
            try {
                commit('SET_TICKET_LOADING', true);

                const res = await apiService.get(kTicketsApi);
                console.log("res:", res.data);
                commit('SET_TICKET', res.data);
            } catch (error) {
                commit('SET_ERROR', error?.message);
                console.log(error);
            }
        },
        async getFinalDraw({ commit }) {
            try {
                commit('SET_TICKET_LOADING', true);

                const res = await apiService.post(kGetFinalDrawApi);
                console.log("res:", res.data);
                commit('SET_FINAL_DRAW', res.data);
            } catch (error) {
                commit('SET_ERROR', error?.message);
                console.log(error);
            }
        },
        async addTicket({ commit }, data) {
            try {
                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
           `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_TICKET_LOADING', true);


                const res = await apiService.post(kTicketsApi, data);
                console.log("res:", res.data);
                commit('ADD_TICKET', res.data);

                // commit('SET_TICKET', res.data);
            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
               passing the `error` object as the payload. This mutation is responsible for updating
               the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);
                console.log(error);
            }
        },
        async checkTicket({ commit }, data) {

            try {
                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
           `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_TICKET_LOADING', true);


                const res = await apiService.post(kCheckTicketApi, data);
                console.log("res:", res.data);

                this.dispatch('getTickets');

                // commit('SET_TICKET', res.data);
            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
               passing the `error` object as the payload. This mutation is responsible for updating
               the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);

                $toast.error(error?.message);
                console.log(error);
            }
        },
        async markCollected({ commit }, { id, date }) {
            try {
                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
           `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_TICKET_LOADING', true);


                const res = await apiService.post(kTicketCollectedApi + "/" + id, { 'collected_at': date });
                console.log("res:", res.data);
                commit('UPDATED_TICKET', { id: id, data: res.data },);

                // commit('SET_TICKET', res.data);
            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
               passing the `error` object as the payload. This mutation is responsible for updating
               the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);

                $toast.error(error?.message);
                console.log(error);
            }
        },
        async markDraw({ commit }, { id, date }) {
            try {
                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
           `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_TICKET_LOADING', true);


                const res = await apiService.post(kTicketMarkDrawApi + "/" + id, { 'draw_at': date });
                console.log("res:", res.data);
                commit('UPDATED_TICKET', { id: id, data: res.data },);

                // commit('SET_TICKET', res.data);
            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
               passing the `error` object as the payload. This mutation is responsible for updating
               the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);

                $toast.error(error?.message);
                console.log(error);
            }
        },
        async draw({ commit }) {
            try {
                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
           `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_TICKET_LOADING', true);


                const res = await apiService.post(kTicketDrawApi);
                console.log("res:", res.data);
                $toast.success(res?.message, { position: "top" });
                commit('SET_TICKET', res.data);

                // commit('SET_TICKET', res.data);
            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
               passing the `error` object as the payload. This mutation is responsible for updating
               the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);

                $toast.error(error?.message);
                console.log(error);
            }
        },


        /* The code you provided is an action called `register` in a Vuex store. Actions in Vuex are
        responsible for handling asynchronous operations and updating the state by committing mutations. */
        async deleteTicket({ commit }, id) {

            try {


                commit('SET_TICKET_LOADING', true);

                /* The line `const data = await apiService.post(kRegisterApi, user);` is making an
                asynchronous HTTP POST request to the `kRegisterApi` endpoint using the `apiService`
                module. It is passing the `user` object as the payload of the request. */
                const res = await apiService.delete(kTicketsApi + "/" + id,);
                console.log("res:", res.data);

                commit('DELETE_TICKET', id);

            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
                passing the `error` object as the payload. This mutation is responsible for updating
                the `error` property of the `user` object in the state. */

                $toast.error(error?.message);
                commit('SET_ERROR', error?.message);
                console.error(error);
            }

        }
    },
    mutations: {

        /* The `SET_TICKET_LOADING` mutation is responsible for updating the `loading` property of the `user`
        object in the state. It takes two parameters: `state` and `payload`. */
        SET_TICKET_LOADING: (state, payload) => {
            state.loading = payload;
            state.error = null;
        },


        /* The `SET_USER` mutation is responsible for updating the `data`, `error`, and `loading`
        properties of the `user` object in the state. */
        SET_TICKET: (state, payload) => {
            state.data = payload;
            state.error = null;
            state.loading = false;
        },
        SET_FINAL_DRAW: (state, payload) => {

            state.final_draw_data = payload || {};
            state.error = null;
            state.loading = false;
        },
        ADD_TICKET: (state, payload) => {
            state.data.push(payload);
            state.error = null;
            state.loading = false;
        },
        UPDATED_TICKET: (state, payload) => {
            const index = state.data.findIndex(i => i.id == payload.id);
            const _data = state.data;
            if (index != -1) {
                _data[index] = payload.data;
                state.data = _data;
            }
            state.error = null;
            state.loading = false;
        },
        DELETE_TICKET: (state, payload) => {
            const index = state.data.findIndex(i => i.id == payload);
            const _data = state.data;
            if (index != -1) {
                _data.splice(index, 1);
                state.data = _data;
            }
            state.error = null;
            state.loading = false;
        },

        /* The `SET_ERROR` mutation is responsible for updating the `error` property of the `user` object in
        the state. It takes two parameters: `state` and `payload`. */
        SET_ERROR: (state, payload) => {
            state.error = payload;
            state.loading = false;
        },

        /* The `LOGOUT` mutation is responsible for clearing the user data and token in the state when
        a user logs out. It sets the `data` property of the `user` object to an empty object (`{}`)
        and sets the `token` property to `null`. This ensures that the user is logged out and their
        data is cleared from the state. */
        // LOGOUT: state => {
        //     state.user = userInitialState;
        // },
        LOGOUT: (state) => {
            state.data = [];
            state.final_draw_data = {};
            state.loading = false;
            state.error = null;
        }
    },
};