
<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-10 w-20 text-[#001524]" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                  <img class="h-25 w-auto" src="/assets/img/img/thetiptoplogo.png" alt="Thetiptop" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a :href="item.href"
                            :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon"
                              :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                              aria-hidden="true" />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class=" hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
        <div class="flex h-20 shrink-0 items-center">
          <img class="h-20 w-auto" src="/assets/img/img/thetiptoplogo.png" alt="Thetiptop" />
        </div>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1 uppercase">
                <li v-for="item in navigation" :key="item.name">
                  <a :href="item.href"
                    :class="[item.current ? 'bg-gray-50 text-[#001524]' : 'text-[#001524]:text-[#001524] hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                    <component :is="item.icon"
                      :class="[item.current ? 'text-[#001524]' : 'text-[#001524] group-hover:text-[#001524]', 'h-6 w-6 shrink-0']"
                      aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="lg:pl-72">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

          <form class="relative flex flex-1" action="#" method="GET">
            <!-- <label for="search-field" class="sr-only">Search</label> -->
            <!-- <MagnifyingGlassIcon class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" aria-hidden="true" /> -->
            <!-- <input id="search-field" class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." type="search" name="search" /> -->
          </form>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <span>
              {{ $store.state.user?.data?.first_name }}
              {{ $store.state.user?.data?.last_name }}
            </span>
            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

            <!-- Profile dropdown -->
            <Menu as="div" class="relative">


              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                </svg> -->
                <button type="button" @click="logout"
                  class="uppercase inline-flex items-center gap-x-2 rounded-md bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Déconnexion
                </button>
              </MenuButton>
            </Menu>
          </div>
        </div>
      </div>

      <main class="pt-10">
        <div class="px-4 sm:px-6 lg:px-8 min-h-screen">
          <router-view>

          </router-view>
        </div>
        <Footer></Footer>
      </main>
    </div>

  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, getCurrentInstance, watch, ref, onMounted } from 'vue';

import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  TicketIcon,
  UserGroupIcon
} from '@heroicons/vue/24/outline';

const store = useStore();
const router = useRouter();
let userNav = ref([
  { name: 'Tableau de bord', href: '#', icon: HomeIcon, current: true },
  { name: 'Participer au concours', href: 'draw', icon: HomeIcon, current: true },
  { name: 'Mon Profil', href: 'profiles', icon: UsersIcon, current: false },
  { name: 'Visualiser mes gains', href: 'history', icon: FolderIcon, current: false },
]);
onMounted(() => {
  store.dispatch('getSetting');
})
if (store.state.setting.data.final_draw_at != null) {
  userNav.value.push({ name: 'Final Draw', href: 'final-draw', icon: HomeIcon, current: false })
}

const moderatorNav = [
  { name: 'Tableau de bord', href: '#', icon: HomeIcon, current: true },
  { name: 'Mon profil', href: 'profiles', icon: UsersIcon, current: false },
  { name: 'Visualiser les gains', href: 'history', icon: FolderIcon, current: false },
  // { name: 'Tickets', href: 'tickets', icon: TicketIcon, current: false },
  { name: 'Participants', href: 'participation', icon: CalendarIcon, current: false },
];
const adminNav = [
  { name: 'Tableau de bord', href: '#', icon: HomeIcon, current: true },
  { name: 'Profil', href: 'profiles', icon: UsersIcon, current: false },
  { name: 'Tickets', href: 'tickets', icon: TicketIcon, current: false },
  { name: 'Participants', href: 'participation', icon: CalendarIcon, current: false },
  { name: 'Comptes', href: 'accounts', icon: UserGroupIcon, current: false },
];
const superadminNav = [
  { name: 'Tableau de bord', href: '#', icon: HomeIcon, current: true },
  { name: 'Profil', href: 'profiles', icon: UsersIcon, current: false },
  { name: 'Historiques', href: 'history', icon: FolderIcon, current: false },
  { name: 'Tickets', href: 'tickets', icon: TicketIcon, current: false },
  { name: 'Participants', href: 'participation', icon: CalendarIcon, current: false },
  { name: 'Comptes', href: 'accounts', icon: UserGroupIcon, current: false },
];
let navigation = userNav.value;
const isRole = getCurrentInstance().appContext.config.globalProperties.$isRole;

if (isRole('superadmin')) {
  navigation = superadminNav;
}
if (isRole('admin')) {
  navigation = adminNav;
}
if (isRole('moderator')) {
  navigation = moderatorNav;
}

function logout() {
  store.dispatch('logout');
  router.push({ name: 'login' });
}

// const user = computed(() => store.state.user.data);

</script>
<style scoped></style>
