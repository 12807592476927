<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TagIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Catégories
                    </DialogTitle>
                    <div class="mt-5">
                      <form @submit.prevent="addCategory">
                        <label for="category-name" class="block text-sm font-semibold leading-6 text-gray-900">Catégorie
                          Nom</label>
                        <div class="justify-center flex">
                          <input type="text" name="category-name" id="category-name" v-model="newCategory" autocomplete="off"
                            placeholder="Category name"
                            class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <button type="submit"
                            class="mt-3 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 sm:mt-0 sm:w-auto">
                            <LoadingLabel label="Add" :loading="store.state.category.loading" />
                          </button>
                        </div>
                      </form>
                      <label v-show="store.state.category.error" class="text-red-400">{{ store.state.category.error
                      }}</label>
                      <br>
                      <br>
                      <label for="all-categories" class="block text-sm font-semibold leading-6 text-gray-900">Toutes les catégories</label>
                      <br>
                      <ul class="list-decimal  overflow-auto">
                        <li v-for="(item, index) in store.state.category.data" :key="index"
                          class="flex items-center pl-1 rounded  hover:bg-[#f3f3f3]">
                          <span class="flex-1">{{ item.name }}</span>
                          <button @click="deleteItem(index)"
                            class="px-2 py-1 text-red-600 rounded hover:text-white hover:bg-red-600">
                            <TrashIcon class="w-4 h-4" />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  @click="open = false" ref="cancelButtonRef">Fermer</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- <button
    class="rounded-md bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase"
    @click="open = true">Ajouter Catégorie</button> -->
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TagIcon, TrashIcon, XCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import store from '../store';
import LoadingLabel from './LoadingLabel.vue';

const open = ref(false);
const newCategory = ref('');

onMounted(() => {
  store.dispatch('getCategories');
})

const addCategory = () => {
  if (newCategory.value) {
    store
      .dispatch('addCategory', { name: newCategory.value });
    newCategory.value = '';
  }
};

const deleteItem = (index) => {
  const id = store.state.category.data[index].id;
  store.dispatch('deleteCategory', id);
};
</script>
  
  
  