<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <TagIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left k">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Add Ticket
                    </DialogTitle>


                    <div class="mt-5">
                      <form @submit.prevent="submitForm" class="bg-white">
                        <div class="mb-4">
                          <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Title:</label>
                          <input type="text" id="name" v-model="formData.title" required
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                        </div>

                        <div class="mb-4">
                          <label for="gender" class="block text-gray-700 text-sm font-bold mb-2">Category:</label>
                          <select id="gender" v-model="formData.category_id" required
                            class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option v-for="(item, index) in store.state.category.data" :key="index" :value="item.id">{{
                              item.name }}</option>
                          </select>
                        </div>

                        <div class="mb-4">
                          <label for="description" class="block text-gray-700 text-sm font-bold mb-2">Description:</label>
                          <textarea type="textarea" id="description" v-model="formData.description"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>

                        </div>

                        <div class="mb-4">
                          <label v-show="store.state.ticket.error" class="text-red-400">{{ store.state.category.error
                          }}</label>
                        </div>
                        <div class="mb-4">
                          <button type="submit"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            <LoadingLabel label="Submit" :loading="store.state.ticket.loading" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  @click="open = false" ref="cancelButtonRef">Fermer</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- <button
    class="rounded-md bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase"
    @click="open = true">Ajouter un ticket</button> -->
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TagIcon, TrashIcon, XCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import store from '../store';
import LoadingLabel from './LoadingLabel.vue';

const open = ref(false);
const formData = ref({});

onMounted(() => {
  store.dispatch('getTickets');
})

const submitForm = () => {
  console.log(formData.value);
  store.dispatch('addTicket', formData.value);
  formData.value = {};
}


</script>
  
  
  