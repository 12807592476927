<template>
    <div>
        <input type="file" :disabled="isLoading" @change="handleFileChange" accept=".xlsx" />
        <br>
        <br>
        <a href="javascript:void(0)" @click="downloadSample">download_sample.xlsx</a>
        <br>
        <br>
        <button v-show="excelData.length" @click="importTickets" :disabled="isLoading"
            class="rounded-md bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">
            <LoadingLabel label="Import" :loading="isLoading" />
        </button>
        <br>
        <br>
        <div v-if="excelData.length > 0">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <!-- Render Excel data here -->
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-6 py-3" v-for="header in excelData[0]" :key="header">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        v-for="(row, index) in excelData.slice(1)" :key="index">
                        <td class="px-6 py-4" v-for="cell in row" :key="cell">{{ cell }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  

<script setup>
import { ref, onMounted } from 'vue';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import store from '../store';
import exportService from '../services/helper';
import apiservice, { kImportTicketApi } from '../services/apiservice';
import { useToast } from 'vue-toast-notification';
import LoadingLabel from '../components/LoadingLabel.vue';
const $toast = useToast();

let excelData = ref([]);
let isLoading = ref(false);


onMounted(() => {
    store.dispatch('getCategories');
})

const importTickets = async () => {
    isLoading.value = true;
    try {
        let categoryId = store.state.category.data.map(i => i.id);
        let title = store.state.category.data.map(i => i.name);
        let data = [...excelData.value];
        let newData = toTickets(data.splice(1), title, categoryId);
        const res = await apiservice.post(kImportTicketApi, newData);
        if (res.status == "SUCCESS") {
            $toast.success(res.message);
        } else {
            $toast.error(res?.message);
        }
        console.log(res);
    } catch (error) {
        $toast.error(error);
    }
    isLoading.value = false;

}
const toTickets = (excelJson, title, categoryId) => {
    let data = [];
    excelJson.forEach((row, indexi) => {
        row.forEach((cell, indexj) => {
            data.push({
                title: title[indexj],
                category_id: categoryId[indexj],
                description: "",
                code: cell
            })

        })

    })
    return data;
}
const downloadSample = () => {
    let firstRow = store.state.category.data.map(i => i.name);
    let row1 = {};
    firstRow.forEach((i, index) => {
        row1[i] = "Ticket " + (index + 1);
    });

    exportService.exportAsExcelFile([row1], "ticket_sample_import", firstRow);
}
const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Read the Excel file
    const reader = new FileReader();
    reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        excelData.value = jsonData;
        console.log(jsonData);
    };
    reader.readAsArrayBuffer(file);
}

</script >

  