import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueConfetti from 'vue-confetti'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import VueMeta from 'vue-meta';
import { createMetaManager } from 'vue-meta';

// captcha 
import { VueReCaptcha } from 'vue-recaptcha-v3';


// on import notre variable store
import App from './App.vue'
import router from './router'
import store from './store/index';

// on import notre index css de tailwindcss
import './input.css'
import './style.css'

import './assets/main.css'

const app = createApp(App)
// on va utiliser notre store
app.use(store)
app.use(createPinia())
app.use(router)
app.use(ToastPlugin);
app.use(VueConfetti);
app.use(VueReCaptcha, { 
    siteKey: '6LfHl_0oAAAAABIVo7lzh7VFLPJ5mfi2V8KAljJo',
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true
        }
    })
// app.use(VueReCaptcha, {
//     siteKey: '6LfHl_0oAAAAABIVo7lzh7VFLPJ5mfi2V8KAljJo',
//     // loaderOptions: {
//     //   autoHideBadge: true
//     // }
//   })
// Define a global function
function isRole(name) {

    return store.state.user.data.roles[0].name === name;

}

// Add the global function to Vue app's config.globalProperties
app.config.globalProperties.$isRole = isRole;

// vue meta
const metaManager = createMetaManager();
app.use(metaManager);

app.mount('#app')
