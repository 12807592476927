// apiService.js
import { create } from 'apisauce';
import { baseURL } from '../config/constants';
import store from '../store';

// Create an instance of Apisauce
let api = create({
    baseURL: baseURL, // Replace with your API base URL
    timeout: 100000, // Timeout for requests in milliseconds
});

// Add a global request interceptor
api.addRequestTransform(request => {
    const token = store.state.user.token; // Get the token from Vuex or localStorage
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }
});

// Define your API methods
const apiService = {
    // GET request
    async get(url, params = undefined) {
        try {

            const response = await api.get(url, params); // Replace with your endpoint
            if (response.ok) {
                return response.data;
            }
            throw new Error(response.data?.message);
        } catch (error) {
            throw error;
        }
    },

    // POST request
    async post(url, payload) {
        try {

            const response = await api.post(url, payload); // Replace with your endpoint and payload
            console.log("response:", response);
            if (response.ok) {
                return response.data;
            }
            throw new Error(response.data?.message);
        } catch (error) {
            throw error;
        }
    },

    // PUT request
    async put(url, payload) {
        try {
            const response = await api.put(url, payload); // Replace with your endpoint and payload
            if (response.ok) {
                return response.data;
            }
             throw new Error(response.data?.message);
        } catch (error) {
            throw error;
        }
    },

    // DELETE request
    async delete(url, payload) {
        try {
            const response = await api.delete(url, payload); // Replace with your endpoint and payload
            if (response.ok) {
                return response.data;
            }
             throw new Error(response.data?.message);
        } catch (error) {
            throw error;
        }
    },
};

export default apiService;


export const kRegisterApi = "/auth/register";
export const kLoginApi = "/auth/login";
export const kLogoutApi = "/auth/logout";
export const kCategoriesApi = "/categories"; 
export const kTicketsApi = "/tickets"; 
export const kSettingApi = "/setting"; 
export const kParticipantsApi = "/participants"; 
export const kTicketCollectedApi = "/collected"; 
export const kTicketMarkDrawApi = "/mark-draw"; 
export const kTicketDrawApi = "/draw"; 
export const kCheckTicketApi = "/check-ticket"; 
export const kCheckFinalDrawApi = "/check-final-draw"; 
export const kGetFinalDrawApi = "/final-draw"; 
export const kCheckTicketPublicApi = "/check-ticket-public"; 
export const kAllTicketsApi = "/all-tickets"; 
export const kAccountsApi = "/accounts"; 
export const kImportTicketApi = "/import-tickets"; 