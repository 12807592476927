<template>
  <nav class="border-b border-gray-200 bg-[#FFF]">
    <Disclosure as="nav" class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <img class="h-20 w-auto" src="/assets/img/img/thetiptoplogo.png" alt="Thetiptop" />
          </div>
          <div class="hidden sm:-my-px sm:ml-20 sm:flex sm:space-x-8 uppercase">
  
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <a href="home" class="border-indigo-500 font-semibold  text-[#001524] inline-flex items-center border-b-2 px- pt- text-1xl font-medium" aria-current="page">Accueil</a>
            <a href="dotations" class="border-transparent font-semibold text-[#001524] hover:hover:bg-[#006941]  hover:text-[#FFF] inline-flex items-center border-b-2 px-1 pt-1 text-1xl font-medium">Thétiptop</a>
            <a href="reglement" class="border-transparent font-semibold text-[#001524] hover:hover:bg-[#006941]  hover:text-[#FFF] inline-flex items-center border-b-2 px-1 pt-1 text-1xl font-medium">Réglement</a>
            <a href="contact" class="border-transparent font-semibold text-[#001524] hover:hover:bg-[#006941]  hover:text-[#FFF] inline-flex items-center border-b-2 px-1 pt-1 text-1xl font-medium">Nous contacter</a>
            <!-- <a href="login" class="border-transparent font-semibold text-[#001524] hover:hover:bg-[#006941]  hover:text-[#FFF]  inline-flex items-center border-b-2 px-1 pt-1 text-1xl font-medium">Login</a> -->
       </div>
        </div>
          </div>

        <!-- <div class="-mr-2 flex items-center sm:hidden"> -->

          
          <div class="absolute inset-y-0 left-20 flex items-center sm:hidden">

            <!-- Mobile menu button -->
            <DisclosureButton class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" aria-controls="mobile-menu">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
           <!-- Menu open: "hidden", Menu closed: "block" -->
           <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          <!-- Menu open: "block", Menu closed: "hidden" -->
          <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
            </DisclosureButton>
        </div>
      </div>

    <DisclosurePanel class="sm:hidden uppercase" >
      <div class="border-t border-gray-200 pb-3 pt-4">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">{{ user.first_name }}</div>
            <div class="text-sm font-medium text-gray-500">{{ user.last_name }}</div>
          </div>
          <button @click="logout" type="button" class="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span class="absolute -inset-1.5" />
            <span class="sr-only">View notifications</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
            </svg>
            <p @click="logout"> Logout</p>
          </button>
        </div>
        </div>

      <div class="space-y-1 pb-3 pt-2">
        <!-- Current: "border-indigo-500 bg-indigo-50 text-indigo-700", Default: "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800" -->
        <a href="home" class="border-indigo-500 bg-indigo-50 text-[#001524] block border-l-4 py-2 pl-3 pr-4 text-base font-medium" aria-current="page">Accueil</a>
        <a href="dotations" class="border-transparent text-[#001524] hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium">Thétiptop</a>
        <a href="reglement" class="border-transparent text-[#001524] hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium">Réglement</a>
        <a href="contact" class="border-transparent text-[#001524] hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium">Nous contacter</a>
        <!-- <a href="login" class="border-transparent text-[#001524]  hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium">Login</a> -->
      </div>

    </DisclosurePanel>
  </Disclosure>
  <div class="py-10">
      <!-- rooting page -->
      <main>
        <router-view>
        </router-view>
      </main>
  </div>
  </nav>
  <!-- </footer> -->
  <footer class="relative bg-[#001524] fixed bottom-0 inset-x-0 z-[10]">

    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-10 sm:py-24 lg:px-8">
      <nav class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
        <div class="pb-6">
          <a href="reglement" class="text-sm leading-6 text-[#FFF] hover:text-[#FFF]">REGLEMENTS</a>
        </div>
        <div class="pb-6">
          <a href="dotations" class="text-sm leading-6 text-[#FFF] hover:text-gray-900">DOTATIONS</a>
        </div>
        <div class="pb-6">
          <a href="mentions" class="text-sm leading-6 text-[#FFF] hover:text-[#FFF]">MENTIONS LEGALES</a>
        </div>
        <div class="pb-6">
          <a href="confidentialites" class="text-sm leading-6 text-[#FFF] hover:text-[#FFF]">POLITIQUE DE
            CONFIDENTIALITE</a>
        </div>
        <div class="pb-6">
          <a href="cookies" class="text-sm leading-6 text-[#FFF] hover:text-[#FFF]">CHARTE DES COOKIES</a>
        </div>
      </nav>
      <p class="mt-10 text-center text-xs leading-5 text-[#FFF]">&copy; 2024 Thetiptop. All rights reserved.</p>
    </div>

  <CookieBanner />
  </footer>
</template>
  <script>
  import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
  import CookieBanner from '../components/CookieBanner.vue'
  // import { Bars3Icon, BellIcon, XMarkIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
  // import { ref } from 'vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'

  // const open = ref(true)


  const navigation = [
    { name: 'Accueil', href: 'home', current: true },
    { name: 'Réglement', href: 'gameprivacy', current: false },
    { name: 'Le jeu concours', href: 'dotations', current: false },
    { name: 'Mon compte', href: 'profil', current: false },
    { name: 'Nous contacter', href: 'contact', current: false },
  ]


  export default {
    components: {
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
      CookieBanner
      // Menu,
      // MenuButton, 
      // MenuItem, 
      // MenuItems,
      // Bars3Icon, 
      // BellIcon, 
      // XMarkIcon,
      // useStore,
      // computed,
  },

    setup() {
      // use data on store a
      const store = useStore();

      // logout 
      const router = useRouter();

            // logout fonction
        function logout(){
          store.commit('logout');
          router.push({ name: 'login'})
      }



      return {
        user: computed(() => store.state.user.data),
        navigation,
        logout
      }
    }

  }

  </script>
    <style scoped>
  
  </style>
