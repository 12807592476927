import { createStore } from 'vuex';
import VuexPersist from 'vuex-persist';
import userModule from './user.module';
import categoryModule from './category.module';
import ticketModule from './ticket.module';
import participantsModule from './participants.module';
import accountsModule from './accounts.module';
import settingModule from './setting.module';


const vuexPersist = new VuexPersist({
    key: 'thetiptop-storage', // The key to store the state in local storage/session storage
    storage: window.localStorage, // Use window.localStorage or window.sessionStorage
});

/* The `userInitialState` constant is defining the initial state for the `user` object in the Vuex
store. It has four properties: */


const store = createStore({
    modules: {
        user: userModule,
        category: categoryModule,
        ticket: ticketModule,
        participants: participantsModule,
        accounts: accountsModule,
        setting: settingModule
    },
    plugins: [vuexPersist.plugin]
})

export default store;