<template>
  <header class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900">Charte sur les Cookies</h1>
    </div>
  </header>
  <main>
        <!-- images -->
        <div>
          <img src="/assets/img/img/img2.jpg" alt="thetiptop" class=" object-coover h-20 w-full object-cover">
      </div>
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-1xl text-align: justify ">
      <!-- Your content -->
      <!-- https://statics.lucky-cycle.com/files/thetiptop/legal/CHARTE_COOKIES_220331.pdf -->
    <p>Cette charte sur les cookies décrit la façon dont les cookies sont utilisés sur notre site internet et les 
moyens mis à votre disposition pour paramétrer le dépôt de cookies sur votre ordinateur ou mobile.</p>
<br>
<strong>Qu'est-ce qu'un cookie ou traceur ?</strong>
<p>Un cookie ou traceur est un petit fichier enregistré sur le disque dur de votre ordinateur ou sur votre 
mobile  à  la  demande  du  serveur  gérant  le  site  internet  visité  (ci-après  le  "Site").  Il  contient  des 
informations sur la navigation effectuée sur les pages du Site. Les cookies susceptibles d'être déposés 
sur le  Site  ont  notamment  pour  finalité  d’assurer  son  fonctionnement  optimal  en  conservant  vos 
informations de navigation le temps de votre session, de permettre l’établissement de statistiques de 
mesures  d’audience  et  l’émission  de  publicités  adaptées  à  vos  centres  d’intérêts,  ainsi  que 
d’améliorer l’interactivité du Site via les réseaux sociaux.</p>
<br>
<strong>Qui peut déposer des cookies ? </strong>
<p>C'est le responsable du traitement des données du Site, c’est-à-dire la personne ou l'organisme qui 
fixe les objectifs et les modalités de collecte et de traitement de vos  données personnelles. Vous 
pouvez voir les modalités de traitement de vos données personnelles en cliquant sur le lien suivant : 
la Politique de Confidentialité. 
Des partenaires tiers sont également susceptibles de déposer des cookies.</p>
<br>
<strong>Comment fonctionnent les cookies ? </strong>
<p>Le  principe  des  cookies  fait  partie  du  protocole  HTTP  utilisé  pour  les  échanges  sur  internet.  Le 
navigateur de votre ordinateur (Internet Explorer, Firefox, Chrome, Safari, etc.) ou de votre mobile 
gère les cookies ou traceurs. C'est lui qui reçoit l'ordre de les enregistrer de la part du serveur. C'est 
également lui qui permet de les contrôler selon vos souhaits. Vous pouvez ainsi paramétrer le dépôt 
de cookies, mais le refus de certains cookies peut limiter le fonctionnement du Site. Enfin, nous vous 
informons que  chaque  cookie  dispose  d'une  durée de  vie  limitée  qui  est  indiquée  au  paragraphe 
suivant. </p>
<br>
<strong>Combien de temps sont conservés les cookies ? </strong>
<p>La durée de vie des cookies ou traceurs est limitée à une durée permettant d'aboutir aux finalités 
fixées par le responsable de traitement dans la Politique de Confidentialité. La durée de conservation 
de chaque cookie déposé sur le Site est précisée dans le Centre de paramétrage des cookies du Site.  
 
Les  informations  collectées  par  l'intermédiaire  de  ces  traceurs  sont  conservées  pour  une  durée 
maximale de : 
-  25 mois pour les cookies de mesure d'audience 
-  6 mois pour la conservation du choix du consentement ou du refus</p>
<strong>Quels types de cookies peuvent être présents sur le Site ? </strong>
<p>Les cookies que nous sommes susceptibles d'utiliser sont répartis en quatre catégories en  fonction 
de leur objectif :  
-  les cookies strictement nécessaires,  
-  les cookies de performance et de mesure d'audience, 
-  les cookies de personnalisation,  
-  et les cookies pour une publicité ciblée.  </p>
<br>
<strong>Cookies strictement nécessaires : </strong>
<p>Ces cookies sont indispensables au bon fonctionnement du Site et 
ne peuvent pas être désactivés de nos systèmes. Ils sont donc exemptés de consentement préalable. 
Ils  ne  sont  activés  qu'en  réponse  à  des  actions  que  vous  effectuez  et  qui  correspondent  à  une 
demande de services, comme la configuration de vos préférences de confidentialité, la connexion ou 
le remplissage de formulaires. Vous pouvez configurer votre navigateur pour bloquer ou être alerté 
de  l'utilisation  de  ces  cookies.  Cependant,  si  cette  catégorie  de  cookies  -  qui  ne  stocke  aucune 
donnée personnelle - est  bloquée, certaines parties du  Site  ne  pourront  pas fonctionner. Certains 
sites n'utilisent que ce type de cookies.  
Exemples : choix de la langue, connexion au compte du Site</p>
<br>
<strong>Cookies de performance et de mesure d'audience :</strong>
<p>Ces cookies nous permettent de déterminer le 
nombre de  visites  et  les  sources  du  trafic  sur notre  Site,  afin  d'en  mesurer  et  d’en  améliorer  les 
performances. Ils nous aident  également  à identifier les  pages les  plus  ou  les moins visitées, et à 
évaluer  comment  les  visiteurs  naviguent  sur  le  Site.  Toutes  les  informations  collectées  par  ces 
cookies sont agrégées et donc anonymisées. Si vous n'acceptez pas cette catégorie de cookies, nous 
ne pourrons pas savoir quand vous avez réalisé votre visite sur notre Site. 
Exemples : Google analytics </p>
<br>
<strong>Cookies de personnalisation de contenu :</strong>
<p>Ces cookies permettent d'améliorer les fonctionnalités du 
site et la personnalisation de son contenu. Ils peuvent être définis par nous ou par des partenaires 
tiers dont nous avons ajouté les services à nos pages. Si vous n'acceptez pas ces Cookies, certains ou 
tous ces services seront susceptibles ne pas fonctionner correctement. 
Exemple : réseaux sociaux présents sur le Site. </p>
<br>
<strong>Cookies  pour  une  publicité ciblée  : </strong>
<p>Ces  cookies  peuvent  être  activés  sur  notre  Site  par  nos 
partenaires publicitaires. Ils peuvent être utilisés par ces entreprises pour établir des profils sur vos 
intérêts et vous proposer des publicités ciblées. Ils fonctionnement uniquement en identifiant votre 
navigateur et votre appareil. Si vous n'acceptez pas cette catégorie de cookies, des publicités moins 
ciblées sur vos intérêts vous seront proposées lors de votre navigation sur notre Site ou d'autres sites 
internet. 
<br>
Si, lors de la création d'un compte thetiptop ou de la Carte thetiptop,  vous nous avez donné votre 
consentement pour recevoir des offres personnalisées en fonction de vos achats et de vos envies, 
nous tiendrons compte de votre choix en plus de votre paramétrage de cookie pour une publicité 
ciblée.  </p>
<br>
<strong>Comment retirer son consentement ? </strong>
<p>Le centre de préférence vous permet de configurer à tout moment l'installation des cookies issus de 
notre  Site.  Nous  y  détaillons  également  les  types  de  cookies  utilisés,  le  caractère  strictement 
nécessaire ou non des cookies déposés, les informations permettant d'identifier les types de cookies 
déposés (nom, catégorie, type, description, durée de vie) et leur durée de conservation. 
Pour y accéder, rendez-vous dans le Centre de paramétrage des cookies sur le Site. </p>
    </div>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: 'Concours Thetiptop',
    meta: [
      { name: 'description', content: 'Un concours | dotations du jeu' }
    ]
  }
}

</script>