<template>
  <header class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900">MENTIONS LEGALES</h1>
    </div>
  </header>
  <main>
        <!-- images -->
        <div>
          <img src="/assets/img/img/img3.jpg" alt="" class=" object-coover h-20 w-full object-cover">
      </div>
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-1xl ">
      
      <!-- Your content -->
  <div> 
Editeur du site :
Société S.C. GALEC, Société coopérative anonyme à capital variable, dont le siège social est situé au 26 quai Marcel Boyer - 94200 IVRY-SUR-SEINE, immatriculée au RCS du Tribunal de commerce de Créteil sous le numéro B 642 007 991.

Directeur de la publication :

Cécile HUET 

Hébergement du site :

HighCo DATA, S.A.S. dont le siège est situé au 110 avenue Galilée, 13799 Aix-en-Provence cedex 3, immatriculée au Registre du Commerce et des Sociétés d’Aix-en-Provence sous le n° 403 096 670.
</div>
<br>
<div> 
  <strong>Confidentialité et données personnelles</strong>
</div>
Les informations personnelles que vous nous transmettez sont strictement confidentielles. Elles ne peuvent être transmises à des tiers sans votre consentement. Pour en savoir plus sur la protection de vos données, voir la Politique de confidentialité.
<br>
<br>
<div> 
  <strong>Propriété intellectuelle</strong>
  Le site ainsi que chacun des éléments qui le compose, notamment les éléments graphiques, textuels, images, bases de données, programmes et développements informatiques du site ainsi que les dénominations sociales, marques et logos sont la propriété exclusive de l’Editeur. Sauf à des fins exclusives d'information pour un usage personnel et privé, toute reproduction, diffusion ou utilisation de quelque nature que ce soit, sans l'autorisation expresse de l’Editeur, est interdite. Toute reproduction, imitation, extraction ou réutilisation engage la responsabilité de son auteur.
</div>

<br>
<div> 
  <strong>Responsabilité</strong>
</div>

L’Editeur s’engage à faire ses meilleurs efforts pour assurer aux utilisateurs une accessibilité du site à tout moment. L’Editeur se réserve le droit de corriger et d'actualiser à tout moment et sans préavis le contenu de ce site. En outre, L’Editeur décline toute responsabilité, pour quelque cause que ce soit, en cas de retard, d'erreur ou d'omission quant au contenu des présentes pages de même qu'en cas d'interruption, de problèmes techniques, y compris de connexion sur le site, ou de non disponibilité du site.

L’Editeur se réserve le droit, pour quelque motif que ce soit et sans avis préalable, de résilier, modifier, suspendre ou d'interrompre l'accès à tout ou partie du site.

L’Editeur ne garantit pas le fait que le serveur accueillant le site soit exempt de virus, d’autres infections logiques sur le site, ou qu'il puisse survenir un problème technique ou une détérioration qui pourrait endommager les composants de l'ordinateur de l'utilisateur ou des données qui pourraient y être stockées.

<br>
<br>
<div>
  <strong>Liens hypertextes</strong>    
  Le site contient des liens hypertextes permettant l'accès à des sites qui ne sont pas édités par l’Editeur. En conséquence, elle ne saurait être tenue pour responsable du contenu des sites ou de tout élément ou service présentés sur ces sites et auxquels vous aurez ainsi l'accès.
  De même, la consultation et la collecte de données personnelles sur ces sites sont soumises aux propres conditions d'utilisation et de protection de ces sites.
  La création de liens hypertextes vers le site doit faire l'objet d'une autorisation préalable de l’Editeur.
  
</div>
<br>
<br>
<div>
  <strong>Cookies</strong>
  Les cookies sont des fichiers textes déposés sur votre appareil (ordinateur, mobile, tablette) lors de la consultation d’un site internet. Ils enregistrent des informations relatives à votre navigation sur ce site. Les cookies ne peuvent être consultés ou modifiés que par leur émetteur.
  Pour plus d’information, vous pouvez consulter la Politique de confidentialité.
</div>

    </div>
  </main>
</template>

<script>


export default {
  name: 'Mentions',
  metaInfo: {
    title: 'Mentions Légales',
    meta: [
      { name: 'description', content: 'La page de mentions légales de notre site internet fournit des informations détaillées sur nos politiques et règlements en matière d\'utilisation du site, de confidentialité des données, de conformité légale, et d\'autres aspects importants. Consultez cette page pour comprendre nos engagements envers nos utilisateurs, nos pratiques de collecte et d\'utilisation des données, ainsi que les règles et réglementations que nous suivons pour assurer une expérience en ligne sûre et transparente.' },
      { property: 'og:title', content: 'Mentions Légale' },
    ],
    link: [
    {rel: 'canonical', href: 'https://thetiptop.click/mentions'}
  ]
  },
};
</script>