import apiService, { kLoginApi, kRegisterApi, kLogoutApi } from '../services/apiservice';

const userInitialState = {
    data: {},
    token: null,
    loading: false,
    error: null
}


export default {
    state: {
        ...userInitialState
    },
    getters: {
    },
    actions: {
        async login({ commit }, user) {
            try {
                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
           `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_USER_LOADING', true);


                /* The line `const data = await apiService.post(kLoginApi, user);` is making an asynchronous
                HTTP POST request to the `kLoginApi` endpoint using the `apiService` module. It is
                passing the `user` object as the payload of the request. The response from the API is
                stored in the `data` variable, which can then be used to update the state or perform
                other operations in the Vuex store. */
                const res = await apiService.post(kLoginApi, user);
                console.log("res:", res.data);

                /* The line `commit('SET_TOKEN', data.token);` is calling a mutation named `SET_TOKEN`
                and passing `data.token` as the payload. This mutation is responsible for updating
                the `token` property of the `user` object in the state. By calling this mutation,
                the `data.token` value received from the API response is stored in the Vuex store,
                allowing it to be accessed and used in other parts of the application. */
                commit('SET_TOKEN', res.data.token);

                /* The line `commit('SET_USER', data.user);` is calling a mutation named `SET_USER` and
                passing `data.user` as the payload. This mutation is responsible for updating the
                `data`, `error`, and `loading` properties of the `user` object in the state. */
                commit('SET_USER', res.data.user);
            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
               passing the `error` object as the payload. This mutation is responsible for updating
               the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);
                console.log(error);
            }
        },


        /* The code you provided is an action called `register` in a Vuex store. Actions in Vuex are
        responsible for handling asynchronous operations and updating the state by committing mutations. */
        async register({ commit }, user) {

            try {

                /* The line `commit('SET_USER_LOADING', true);` is calling a mutation named
                `SET_USER_LOADING` and passing `true` as the payload. */
                commit('SET_USER_LOADING', true);

                /* The line `const data = await apiService.post(kRegisterApi, user);` is making an
                asynchronous HTTP POST request to the `kRegisterApi` endpoint using the `apiService`
                module. It is passing the `user` object as the payload of the request. */
                const res = await apiService.post(kRegisterApi, user);
                console.log("res:", res.data);

                /* The line `commit('SET_TOKEN', data.token);` is calling a mutation named `SET_TOKEN`
                and passing `data.token` as the payload. This mutation is responsible for updating
                the `token` property of the `user` object in the state. By calling this mutation,
                the `data.token` value received from the API response is stored in the Vuex store,
                allowing it to be accessed and used in other parts of the application. */
                commit('SET_TOKEN', res.data.token);

                /* The line `commit('SET_USER', data.user);` is calling a mutation named `SET_USER` and
                passing `data.user` as the payload. This mutation is responsible for updating the
                `data`, `error`, and `loading` properties of the `user` object in the state. */
                commit('SET_USER', res.data.user);

            } catch (error) {
                /* The line `commit('SET_ERROR', error);` is calling a mutation named `SET_ERROR` and
                passing the `error` object as the payload. This mutation is responsible for updating
                the `error` property of the `user` object in the state. */
                commit('SET_ERROR', error?.message);
                console.error(error);
            }

        },
        async logout({ commit }) {
            console.log("Dispatching Logout");
            try {
                const res = await apiService.post(kLogoutApi);
            } catch (error) {
                
            }
            commit('LOGOUT');
        }
    },
    mutations: {

        /* The `SET_USER_LOADING` mutation is responsible for updating the `loading` property of the `user`
        object in the state. It takes two parameters: `state` and `payload`. */
        SET_USER_LOADING: (state, payload) => {
            state.loading = payload;
            state.error = null;
        },

        /* The `SET_TOKEN` mutation is responsible for updating the `token` property of the `user`
        object in the state. It takes two parameters: `state` and `payload`. */
        SET_TOKEN: (state, payload) => {
            state.token = payload;
        },

        /* The `SET_USER` mutation is responsible for updating the `data`, `error`, and `loading`
        properties of the `user` object in the state. */
        SET_USER: (state, payload) => {
            state.data = payload;
            state.error = null;
            state.loading = false;
        },

        /* The `SET_ERROR` mutation is responsible for updating the `error` property of the `user` object in
        the state. It takes two parameters: `state` and `payload`. */
        SET_ERROR: (state, payload) => {
            state.error = payload;
            state.loading = false;
        },

        /* The `LOGOUT` mutation is responsible for clearing the user data and token in the state when
        a user logs out. It sets the `data` property of the `user` object to an empty object (`{}`)
        and sets the `token` property to `null`. This ensures that the user is logged out and their
        data is cleared from the state. */
        // LOGOUT: state => {
        //     state.user = userInitialState;
        // },
        LOGOUT: (state) => {
            state.data = {};
            state.token = null;
            state.loading = false;
            state.error = null;
        }
    },
};