import { createRouter, createWebHistory } from 'vue-router'
import AuthLayout from '../components/AuthLayout.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import DefaultLayout from '../components/DefaultLayout.vue'
import DashboardView from '../views/DashboardView.vue'
import ModalView from '../views/ModalView.vue'
import store from '../store'
import DotationsView from '../views/DotationsView.vue'
import homeView from '../views/HomeView.vue'
import CookiesView from '../views/CookiesView.vue'
import ReglementView from '../views/ReglementView.vue'
import MentionsView from '../views/MentionsView.vue'
import ConfidentialitesView from '../views/ConfidentialitesView.vue'
import ContactView from '../views/ContactView.vue'
import DrawView from '../views/DrawView.vue'
import FinalDrawView from '../views/FinalDrawView.vue'
import DashboardLayout from '../components/DashboardLayout.vue'
import ProfilesView from '../views/ProfilesView.vue'
import ParticipationView from '../views/ParticipationView.vue'
import TicketsView from '../views/TicketsView.vue'
import HistoryView from '../views/HistoryView.vue'
import AccountsView from '../views/AccountsView.vue'
import ImportView from '../views/ImportView.vue'
import Sitemap from '../views/Sitemap.vue';


const routes = [
  {
    path: '/',
    redirect: '/home',
    component: DefaultLayout,
    // meta: {requiresAuth: true},
    children: [
      { path: '/modal', name: 'modal', component: ModalView },
      { path: '/dotations', name: 'dotations', component: DotationsView },
      { path: '/home', name: 'home', component: homeView },
      { path: '/cookies', name: 'cookies', component: CookiesView },
      { path: '/reglement', name: 'reglement', component: ReglementView },
      { path: '/mentions', name: 'mentions', component: MentionsView },
      { path: '/confidentialites', name: 'confidentialites', component: ConfidentialitesView },
      { path: '/contact', name: 'contact', component: ContactView },
      { path: '/login', name: 'login', component: LoginView },
    ]
  },
  {
    path: '/users',
    // name: 'users',
    meta: { requiresAuth: true },
    component: DashboardLayout,
    children: [
      { path: '/dashboard', name: 'dashboard', component: DashboardView },
      { path: '/draw', name: 'draw', component: DrawView },
      { path: '/final-draw', name: 'final-draw', component: FinalDrawView },
      { path: '/profiles', name: 'profiles', component: ProfilesView },
      { path: '/history', name: 'history', component: HistoryView },
      { path: '/profiles', name: 'profiles', component: ProfilesView },
      { path: '/participation', name: 'participation', component: ParticipationView },
      { path: '/tickets', name: 'tickets', component: TicketsView },
      { path: '/accounts', name: 'accounts', component: AccountsView },
      { path: '/import', name: 'import', component: ImportView },

    ]
  },
  {
    path: '/auth',
    redirect: '/login',
    name: 'auth',
    component: AuthLayout,
    meta: { isGuest: true },
    children: [
      { path: '/login', name: 'login', component: LoginView },
      { path: '/register', name: 'register', component: RegisterView },
    ]
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: Sitemap,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.user.token) {
    next({ name: 'login' })
  } else if (store.state.user.token && (to.meta.isGuest)) {
    next({ name: 'home' });
  } else {
    next();
  }
})


export default router;
