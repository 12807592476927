<template>
    <div>
      <!-- Add your sitemap content here -->
  <url>
    <loc>https://www.thetiptop.click/</loc>
    <lastmod>2023-12-05</lastmod>
    <changefreq>daily</changefreq>
    <priority>1.0</priority>
  </url>

  <!-- Add additional URLs for the Vue.js app here -->
  <!-- Example: -->
  <url>
    <loc>https://www.thetiptop.click/home</loc>
    <lastmod>2023-12-05</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.8</priority>
  </url>
   <url>
      <loc>https://www.thetiptop.click/dotations</loc>
    <lastmod>2023-12-05</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.8</priority>
  </url>
  <url>
    <loc>https://www.thetiptop.click/contact</loc>
    <lastmod>2023-12-05</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.5</priority>
  </url>
 
  <url>
    <loc>https://www.thetiptop.click/reglement</loc>
    <lastmod>2023-12-05</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.5</priority>
  </url>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Sitemap',
  };
  </script>