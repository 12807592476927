<template>
    <header class="bg-white shadow inline-flex w-full">
        <div class="mx-auto px-4 py-6 sm:px-6 lg:px-8 ">
            <h1 class="text-3xl font-bold tracking-tight text-gray-900 uppercase">Tirage</h1>
        </div>

        <div class="mx-1">
            <CategoryModal />
        </div>
        <div class="mx-1">
            <TicketModal />
        </div>
        <div class="mx-1">
            <button @click="draw"
                class="rounded-md w-48 bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">
                <LoadingLabel label="T-initial" :loading="$store.state.ticket.loading" />
            </button>
        </div>
        <div class="mx-1">
            <button @click="finalDraw"
                class="rounded-md bg-[#001524] w-48 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">
                <LoadingLabel label="T-final" :loading="$store.state.ticket.loading" />
        </button>
        </div>

    </header>
    <main>
        <!-- Export -->
        <div class=" pt-5 hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-22 lg:flex-col">
            <a href="import">
                <b-button type="button"
                    class="rounded-md bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">IMPORT</b-button>
            </a>
        </div>

        <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-xl ">
            <!-- Your content -->
            <div class="px-4 sm:px-6 lg:px-8">
                <!-- <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <h1 class="text-base font-semibold leading-6 text-gray-900">Transactions</h1>
              <p class="mt-2 text-sm text-gray-700">A table of placeholder stock market data that does not make any sense.</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
            </div>
          </div> -->
                <div class="mt-8 flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        </th>
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Lot</th>
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Numéro</th>
                                        <!-- <th scope="col"
                                            class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Type</th> -->
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Date tirage</th>
                                        <th scope="col"
                                            class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Tirage final du
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr>
                                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">1</td>
                                        <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">Un an de thé valeur de 360€</td>
                                        <!-- <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{
                                            $store.state.ticket.final_draw_data.title }}</td> -->
                                        <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">
                                            {{ $store.state.ticket.final_draw_data.code }}</td>
                                        <!-- <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{
                                            $store.state.ticket.final_draw_data?.category?.name }}
                                        </td> -->
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{
                                            $store.state.ticket.final_draw_data.draw_at }}
                                        </td>
                                        <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                            {{
                                                $store.state.ticket.final_draw_data.final_draw_at
                                            }}

                                        </td>
                                    </tr>

                                    <!-- More transactions... -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div class="mb-5">
                        Total: {{ $store.state.ticket.data.length }}
                    </div>
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                </th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Lot</th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Numéro</th>
                                <!-- <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Type</th> -->
                                <!-- <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Description</th> -->
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Date tirage</th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Collecté le</th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(item, index) in $store.state.ticket.data" :key="index">
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{
                                    index + 1 }}</td>
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{
                                    item.title }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">
                                    {{ item.code }}</td>
                                <!-- <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{
                                    item?.category?.name }}
                                </td> -->
                                <!-- <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.description }}
                                </td> -->
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.draw_at }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.collected_at
                                }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    <button @click="deleteItem(index)"
                                        class="px-2 py-1 text-red-600 rounded hover:text-white hover:bg-red-600">
                                        <TrashIcon class="w-4 h-4" />
                                    </button>
                                    <button v-show="!$isRole('user')" @click="toggleDateModal(true, item.id)"
                                        title="Tick as collected"
                                        class="px-2 py-1 text-red-600 rounded hover:text-white hover:bg-red-600">
                                        <CheckCircleIcon class="w-4 h-4" />
                                    </button>
                                    <!-- <button @click="toggleDateModal(true, item.id)" title="Tick as draw"
                                                class="px-2 py-1 text-red-600 rounded hover:text-white hover:bg-red-600">
                                                <CheckCircleIcon class="w-4 h-4" color="primary" />
                                            </button> -->
                                </td>
                            </tr>

                            <!-- More transactions... -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- <DateModal :show="showDateModal" :onSelect="markDraw" title="Mark Draw" :onClose="() => toggleDateModal(false)" /> -->
        <DateModal :show="showDateModal" :onSelect="collected" title="A été collecté"
            :onClose="() => toggleDateModal(false)" />

    </main>
</template>
  
<script setup>
import CategoryModal from '../components/CategoryModal.vue';
import TicketModal from '../components/TicketModal.vue';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import { TrashIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';
import DateModal from '../components/DateModal.vue';
import LoadingLabel from '../components/LoadingLabel.vue';

let showDateModal = ref(false);
let setID = ref(null);

const store = useStore();
const toggleDateModal = (value, id) => {
    console.log(value);
    setID.value = id;
    showDateModal.value = value;
}
const draw = () => {
    store.dispatch('draw')
}
const finalDraw = () => {
    store.dispatch('getFinalDraw')
}
const markDraw = (date) => {
    console.log("selected date:", date);
    store.dispatch('markDraw', { id: setID.value, date: date });
    toggleDateModal(false, null);
}
const collected = (date) => {
    console.log("selected date:", date);
    store.dispatch('markCollected', { id: setID.value, date: date });
    toggleDateModal(false, null);
}

const deleteItem = (index) => {
    const confirm = window.confirm("Are you sure to delete this item?");
    if (confirm) {
        const id = store.state.ticket.data[index].id;
        store.dispatch('deleteTicket', id);
    }
};

</script>
<style scoped></style>