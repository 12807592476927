
<template>
  <div class="flex min-h-full">
    <div class="flex flex- flex-col justify-center px-4 py-1 sm:px-6 lg:flex-none  x1:px-30">
      <div>
        <div>
          <!-- <img class="h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Thetiptop"> -->
          <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Inscrivez-vous !</h2>
          <p class="mt-2 text-sm leading-6 text-gray-500">
            Vous avez déjà cun compte?
            <a href="login" class="font-semibold text-[#006941] hover:text-indigo-500">Connectez vous ici</a>
          </p>
        </div>

        <div class="mt-5">
          <div>
            <form class="w-full max-w-lg  mb-12" @submit="register">
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="name">
                    Prénom
                  </label>
                  <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="firstName" type="text" placeholder="Jane" v-model="user.first_name">
                  <!-- <p class="text-red-500 text-xs italic">Please fill out this field.</p> -->
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="lastName">
                    Nom
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="lastName" type="text" placeholder="Doe" v-model="user.last_name">
                </div>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="address">
                  Adresse
                </label>
                <input
                  class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="address" type="adresss" placeholder="address" v-model="user.address">
                <!-- <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> -->
              </div>

              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="city">
                    Ville
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="city" type="text" placeholder="Albuquerque" v-model="user.city">
                </div>
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="zip">
                    Code postale
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="zip" type="text" placeholder="90210" v-model="user.zipcode">
                </div>
                <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="state">
                    Région
                  </label>
                  <div class="relative">
                    <select
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="state" v-model="user.state">
                      <option>Auvergne-Rhône-Alpes</option>
                      <option>Bourgogne-Franche-Comté</option>
                      <option>Bretagne</option>
                      <option>Centre-Val de Loire</option>
                      <option>Corse</option>
                      <option>Grand Est</option>
                      <option>Hauts-de-France</option>
                      <option>Île-de-France</option>
                      <option>Normandie</option>
                      <option>Nouvelle-Aquitaine</option>
                      <option>Occitanie</option>
                      <option>Pays de la Loire</option>
                      <option>Provence-Alpes-Côte d'Azur</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap -mx-3 mb-6 pt-5">
                  <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
                      Email
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="email" type="email" placeholder="email" v-model="user.email">
                    <!-- <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> -->
                  </div>
                  <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="password">
                      Mot de passe
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="password" type="password" placeholder="******************" v-model="user.password">
                  </div>
                  <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      for="password_confirmation">
                      Confirmation mot de passe
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="password_confirmation" type="password" placeholder="******************"
                      v-model="user.password_confirmation">
                    <!-- <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> -->
                  </div>

                  <div class="px-3 mx-5 flex items-center justify-between">
                    <div class="flex items-center">
                      <input id="tos" name="tos" type="checkbox" v-model="user.tos"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                      <a href="confidentialites">
                        <label class="ml-3 font-semibold text-[#006941] hover:text-indigo-500">Conditions générales d'utilisation</label></a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-sm leading-6">
                <a href="#" class="font-semibold text-[red] hover:text-indigo-500">{{ $store.state.user.error }}</a>
              </div>
              <div>
                <button type="submit" :disabled="$store.state.user.loading"
                  class="py-2.5 flex h-11 w-full justify-center rounded-md bg-[#006941] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <LoadingLabel label="S'enregistrer" :loading="$store.state.user.loading" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img class="absolute inset-0 h-full w-screen object-cover  py-10" src="/assets/img/img/thelogin2.jpg" alt="">
    </div>
  </div>
</template>
<script setup>
import { watch, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import store from '../store';
import LoadingLabel from '../components/LoadingLabel.vue';

// début vue captcha 3
import { onMounted, onBeforeUnmount } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const recaptchaIns = useReCaptcha().instance

onMounted(() => {
    setTimeout(() => {
        recaptchaIns.value.showBadge()
    }, 1000)
}),
onBeforeUnmount(() => {
    recaptchaIns.value.hideBadge()
}) 

// fin vue captcha 3
const router = useRouter();

watch([store.state.user], () => {
  if (Object.keys(store.state.user.data).length) {
    if (isRole('user')) {
      router.push({
        name: 'draw'
      });
    } else {
      router.push({
        name: 'dashboard'
      });
    }
  }
});

const isRole = getCurrentInstance().appContext.config.globalProperties.$isRole;

const user = {
  first_name: '',
  last_name: '',
  email: '',
  address: '',
  password: '',
  password_confirmation: '',
  city: '',
  state: '',
  zipcode: '',
};


function register(ev) {
  ev.preventDefault();
  console.log("user:", user);
  if (user.tos != true) {
    alert("Please read termes et conditions!");
    return;
  }
  if (user.tos == true) {
    user.tos_at = new Date();
  }
  store
    .dispatch('register', user);

};

</script>