<template>
  <header class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 uppercase">Mes informations</h1>
    </div>
  </header>
  <main>
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-xl ">
      <!-- Your content -->
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Prénom</th>
                    <th scope="col" class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Nom</th>
                    <th scope="col" class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Adresse</th>
                    <th scope="col" class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Code postale</th>
                    <th scope="col" class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Ville</th>
                    <th scope="col" class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ user.first_name }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">{{ user.last_name }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ user.address }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ user.zipcode }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ user.city }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ user.email }}</td>                  
                  </tr>
      
                  <!-- More transactions... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </main>
</template>

<script> 

// import { Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
// import { Bars3Icon, BellIcon, XMarkIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
// import { ref } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

// const open = ref(true)


const navigation = [
  { name: 'Accueil', href: 'home', current: true },
  { name: 'Réglement', href: 'gameprivacy', current: false },
  { name: 'Le jeu concours', href: 'dotations', current: false },
  { name: 'Mon compte', href: 'profil', current: false },
  { name: 'Nous contacter', href: 'contact', current: false },
]


export default {
  components: {
    // Disclosure,
    // DisclosureButton,
    // DisclosurePanel,
    // Menu,
    // MenuButton, 
    // MenuItem, 
    // MenuItems,
    // Bars3Icon, 
    // BellIcon, 
    // XMarkIcon,
    // useStore,
    // computed,
},

  setup() {
    // use data on store a
    const store = useStore();

    // logout 
    const router = useRouter();

          // logout fonction
      function logout(){
        store.commit('logout');
        router.push({ name: 'login'})
    }



    return {
      user: computed(() => store.state.user.data),
      navigation,
      logout
    }
  }

}

</script>
  <style scoped>

</style>