<template>
  <header class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 uppercase">Participations</h1>
    </div>
  </header>
  <main>
    <!-- Export -->
    <div class=" pt-5 hidden lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-22 lg:flex-col">
      <button type="button" @click="exportUsers"
        class="rounded-md bg-[#001524] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">Export</button>
    </div>

    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-xl ">
      <!-- Your content -->
      <div class="px-4 sm:px-6 lg:px-8">
        <!-- <div class="sm:flex sm:items-center">
          <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Transactions</h1>
            <p class="mt-2 text-sm text-gray-700">A table of placeholder stock market data that does not make any sense.</p>
          </div>
          <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
          </div>
        </div> -->
        <form action="">

          <h3 class="mb-4 font-semibold text-gray-900 dark:text-white">Filtrer</h3>
          <ul
            class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input id="horizontal-list-radio-license" type="radio" v-model="drawFilter" @change="handleFilterChange"
                  value="1" name="list-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <label for="horizontal-list-radio-license"
                  class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Participants</label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input id="horizontal-list-radio-id" type="radio" v-model="drawFilter" @change="handleFilterChange"
                  value="2" name="list-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <label for="horizontal-list-radio-id"
                  class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Inscrit sans participation</label>
              </div>
            </li>
            <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
              <div class="flex items-center pl-3">
                <input id="horizontal-list-radio-millitary" type="radio" v-model="drawFilter" @change="handleFilterChange"
                  value="0" name="list-radio"
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                <label for="horizontal-list-radio-millitary"
                  class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Tous les participants</label>
              </div>
            </li>
            <!-- <li class="w-full dark:border-gray-600">
              <div class="flex items-center pl-3">
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                  Filter</button>
              </div>
            </li> -->
          </ul>


        </form>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col"
                      class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Prénom</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Nom</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Adresse</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Code postale</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Ville
                    </th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Email
                    </th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tickets
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(item, index) in store.state.participants.data">
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ item.first_name }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">{{ item.last_name
                    }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{ item.address }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.zipcode }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.city }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.email }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.tickets?.length }}</td>
                  </tr>

                  <!-- More transactions... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import store from '../store';
import exportService from '../services/helper';

let drawFilter = ref('1');

onMounted(() => {
  handleFilterChange();
})

const handleFilterChange = () => {
  store.dispatch('getParticipants', drawFilter.value);
}

const exportUsers = () => {
  let data = [];
  store.state.participants.data.forEach(user => {
    let obj = [];
    obj["FIRST_NAME"] = user.first_name;
    obj["LAST_NAME"] = user.last_name;
    obj["EMAIL"] = user.email;
    obj["CITY"] = user.city;
    obj["STATE"] = user.state;
    obj["ZIPCODE"] = user.zipcode;
    obj["ADDRESS"] = user.address;
    obj["TOS"] = user.tos_at == null ? 'NO' : 'YES';
    obj["TOS_AT"] = user.tos_at;
    obj["NUMBER_OF_PRIZES"] = user.tickets.length;
    obj["COLLECTED_COUNT"] = user.tickets.filter(i => i.collected_at != null).length;
    obj["NOT_COLLECTED_COUNT"] = user.tickets.filter(i => i.collected_at == null).length;
    obj["TICKETS"] = user.tickets.map(i => i.code).join(',');
    // obj["PRIZES"] = '';

    data.push(obj);
  })
  exportService.exportAsExcelFile(data, "participants");
}

</script>