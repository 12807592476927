<template>
  
  <header class="bg-white shadow">
    <div class="mx-auto max-w-8xl px-10 py-6 sm:px-6 lg:px-8">
      <h1 class="text-2xl font-bold tracking-tight text-gray-900 uppercase">Politique de confidentialité</h1>
    </div>
  </header>
  <main>
    <!-- images -->
      <div>
          <img src="/assets/img/img/img7.jpg" alt="thetiptop" class=" object-coover h-20 w-full object-cover">
      </div>

    <!-- good section -->
    <div class="mx-auto max-w-8xl px-4 py-6 sm:px-6 lg:px-8 text-1xl text-align: justify">

      <!-- https://statics.lucky-cycle.com/files/thetiptop/legal/POLITIQUE-CONFIDENTIALITE-GALEC-220331.pdf -->

    <!-- text fonfidentialites -->
    <h2><strong>1 Politique de confidentialit&eacute;</strong></h2>
<p>Les traitements de donn&eacute;es &agrave; caract&egrave;re personnel vis&eacute;s par la pr&eacute;sente politique de confidentialit&eacute; sont soumis au droit fran&ccedil;ais, et notamment &agrave; la Loi Informatique et Libert&eacute;s du 6 janvier 1978 dans ses versions modifi&eacute;es, et au R&egrave;glement UE 2016/679 du 27 avril 2016 relatif &agrave; la protection des personnes physiques &agrave; l'&eacute;gard du traitement des donn&eacute;es &agrave; caract&egrave;re personnel et &agrave; la libre circulation de ces donn&eacute;es (RGPD), ci-apr&egrave;s d&eacute;nomm&eacute;e &laquo; la R&egrave;glementation des donn&eacute;es personnelles &raquo;.</p>

<br>
<h2><strong>1.1 Responsable du traitement</strong></h2>
<p>L&rsquo;Editeur &eacute;tant la soci&eacute;t&eacute; SC GALEC, dont le si&egrave;ge social est situ&eacute; 26 quai Marcel Boyer &ndash; 94 200 Ivry-sur- Seine, immatricul&eacute;e au RCS CRETEIL sous le num&eacute;ro 642 007 991 RCS CRETEIL. Ci-apr&egrave;s d&eacute;nomm&eacute;e &laquo; Editeur &raquo;.</p>
<br>
<h2><strong>1.2 A qui s&rsquo;adresse notre politique de protection des donn&eacute;es ?</strong></h2>
<p>Cette politique s&rsquo;applique &agrave; tous les visiteurs du site web sous l'URL &laquo; hcd.jeu.thetiptop &raquo; et ses d&eacute;riv&eacute;s (ci- apr&egrave;s &laquo; Notre Site Web &raquo;), nos membres inscrits, ainsi qu&rsquo;aux personnes qui nous demandent des informations.</p>
<p>Le terme &laquo; donn&eacute;es &agrave; caract&egrave;re personnel &raquo; (ci-apr&egrave;s &laquo; Donn&eacute;es &raquo;) a le sens d&eacute;fini dans la R&egrave;glementation des donn&eacute;es personnelles.</p>
<p>Afin que vous puissiez naviguer sur Notre Site Web en toute confiance, nous nous engageons &agrave; traiter vos Donn&eacute;es conform&eacute;ment &agrave; la R&egrave;glementation donn&eacute;es personnelles. Cette politique de confidentialit&eacute; vise &agrave; d&eacute;crire en toute transparence la mani&egrave;re et la raison dont sont trait&eacute;es vos Donn&eacute;es. Le terme</p>
<p>&laquo; traitement de Donn&eacute;es &raquo; est ici utilis&eacute; pour d&eacute;signer la collecte et le traitement des Donn&eacute;es.</p>
<br>
<h2><strong>1.3 Cat&eacute;gories de Donn&eacute;es trait&eacute;es</strong></h2>
<p>Nous sommes susceptibles de traiter les Donn&eacute;es suivantes :</p>
<br>
<h3><strong>Les Donn&eacute;es que vous renseignez</strong></h3>
<ul>
<li>nom, pr&eacute;nom</li>
<li>adresse e-mail</li>
<li>si vous &ecirc;tes inscrit &agrave; notre site et/ou &agrave; l&rsquo;opt-in que le site propose</li>
<li>facultatif et/ou en fonction des besoins des actions : votre adresse postale, votre date de naissance, num&eacute;ro de t&eacute;l&eacute;phone, le canal de provenance du client sur Notre Site Internet (site Internet pr&eacute;c&eacute;dent, application, magasin physique&hellip;)</li>
<li>vos r&eacute;ponses &agrave; toute information suppl&eacute;mentaire que nous vous demanderons afin que vous puissiez b&eacute;n&eacute;ficier des avantages li&eacute;s directement ou indirectement &agrave; vos centres d&rsquo;int&eacute;r&ecirc;t. Ces r&eacute;ponses sont purement facultatives et ne vous emp&ecirc;che pas de profiter de tous nos autres</li>
</ul>
<p>produits/services. Mais plus vous nous transmettrez d&rsquo;informations, plus nous serons en mesure</p>
<p>de vous proposer des produits et services qui correspondent &agrave; vos centres d&rsquo;int&eacute;r&ecirc;t.</p>
<ul>
<li>vos informations transactionnelles (achat, ouverture newsletter, clics et participation(s) aux</li>
</ul>
<p>actions, &hellip;)</p>
<ul>
<li>date d&rsquo;enregistrement des informations</li>
</ul>
<p>Abonnement &agrave; une Newsletter (si consentement) :</p>
<ul>
<li>adresse e-mail</li>
</ul>
<p>Offres partenaires (si consentement) :</p>
<ul>
<li>adresse e-mail</li>
</ul>
<br>
<h3><strong>Les Donn&eacute;es que nous collectons automatiquement lorsque vous utilisez nos services</strong></h3>
<p>Afin de vous permettre d&rsquo;acc&eacute;der aux services propos&eacute;s par notre site web, nous collectons des Donn&eacute;es d&rsquo;utilisation afin de, par exemple, mesurer l&rsquo;audience de notre site web ou le nombre de pages vues. Nous utilisons les identifiants de connexion de vos appareils mobiles, votre adresse IP et des traceurs pour vous identifier et pour enregistrer votre activit&eacute;.</p>
<p>Nous pouvons &eacute;galement &ecirc;tre amen&eacute;s &agrave; collecter des donn&eacute;es techniques sur votre connexion internet, votre navigateur et vos appareils (exemple : iPhone ou Android, PC ou Mac, taille de police, etc.). Pour plus d&rsquo;informations sur l&rsquo;utilisation des cookies, consultez le point II) &laquo; cookies &raquo; ci-dessous.</p>
<br>
<h3><strong>Les Donn&eacute;es concernant les mineurs</strong></h3>
<p>Notre site web et/ou nos campagnes promotionnelles th&eacute;matiques ne sont pas destin&eacute;es aux mineurs. Si toutefois, vous deviez constater qu&rsquo;un mineur dont vous &ecirc;tes responsable s&rsquo;est inscrit sur notre site web alors que vous vous y opposez, prenez contact avec nous et nous supprimerons imm&eacute;diatement des Donn&eacute;es de nos bases.</p>
<br>
<h2><strong>1.4 Utilisations de vos Donn&eacute;es et bases l&eacute;gales</strong></h2>
<br>
<h3><strong>Traitement de vos Donn&eacute;es sur la base de l&rsquo;ex&eacute;cution du contrant (Article 6 b. du RGPD)</strong></h3>
<p>Nous traitons vos Donn&eacute;es, d&egrave;s lors que vous acceptez les conditions de participation au jeu pour les finalit&eacute;s suivantes :</p>
<ul>
<li>g&eacute;rer la participation &agrave; une action promotionnelle ou l&rsquo;impression d&rsquo;un bon de r&eacute;duction ;</li>
<li>r&eacute;pondre aux questions via notre site web ou tout autre canal de communication.</li>
</ul>
<br>
<h3><strong>Traitement de vos Donn&eacute;es sur la base du consentement de la personne concern&eacute;e (Article 6 a. du RGPD)</strong></h3>
<p>Nous traitons vos Donn&eacute;es, d&egrave;s lors que vous y consentez, pour les finalit&eacute;s suivantes :</p>
<ul>
<li>vous envoyer nos newsletters ;</li>
<li>vous communiquer des informations sur les produits et services de l&rsquo;Enseigne thetiptop par e-mail, courrier postal ou t&eacute;l&eacute;phone (si vous nous avez communiqu&eacute; ces informations) ;</li>
</ul>
<ul>
<li>communiquer vos Donn&eacute;es &agrave; nos partenaires commerciaux afin qu&rsquo;ils vous contactent &agrave; des fins de prospection marketing pour leurs produits et services, &eacute;ventuellement sur base de votre profil et moyennant votre autorisation sp&eacute;cifique pr&eacute;alablement &agrave; ce transfert.</li>
</ul>
<p>Vous pouvez retirer votre consentement &agrave; tout moment dans les conditions d&eacute;crites &agrave; l&rsquo;article 8 ci- dessous.</p>
<br>
<h3><strong>Utilisation de vos Donn&eacute;es sur la base de notre int&eacute;r&ecirc;t l&eacute;gitime (Article 6 f. du RGPD)</strong></h3>
<p>Lorsque nous avons un int&eacute;r&ecirc;t l&eacute;gitime, soit dans le cadre de nos activit&eacute;s marketing, soit dans le cadre de la r&eacute;alisation concr&egrave;te de nos activit&eacute;s &eacute;conomiques, nous collectons et traitons des Donn&eacute;es en vue des finalit&eacute;s suivantes :</p>
<ul>
<li>afin d&rsquo;assurer le respect des conditions de participation et le traitement des r&eacute;clamations ;</li>
<li>afin de faire des analyses statistiques ou de tendance pour des fins de gestion financi&egrave;re, de marketing ou de reporting ;</li>
<li>afin de pr&eacute;venir et de combattre les &eacute;ventuelles fraudes et abus.</li>
</ul>
<br>
<h3><strong>Utilisation de vos Donn&eacute;es sur la base du respect de nos obligations l&eacute;gales (Article 6 c. du RGPD)</strong></h3>
<p>Afin de satisfaire aux obligations l&eacute;gales auxquelles nous sommes, dans certains cas, soumis &agrave; des obligations l&eacute;gales imposant le traitement et/ou la transmission de certaines Donn&eacute;es personnelles aux autorit&eacute;s comp&eacute;tentes (police, administration fiscale, etc.).</p>
<br>
<h2><strong>1.5 Transmission de vos donn&eacute;es &agrave; des tiers</strong></h2>
<p>Vos donn&eacute;es peuvent &ecirc;tre communiqu&eacute;es &agrave; des tiers dans les cas suivants :</p>
<ul>
<li>&agrave; certains de nos sous-traitants dans le cadre de prestations pour notre compte (exemple : agence</li>
</ul>
<p>marketing, prestataire d&rsquo;h&eacute;bergement, etc.) et uniquement sur base de nos instructions ;</p>
<ul>
<li>&agrave; certains de nos partenaires commerciaux sous r&eacute;serve d&rsquo;avoir pr&eacute;alablement obtenu votre</li>
</ul>
<p>consentement.</p>
<br>
<h2><strong>1.6 Transfert de donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne</strong></h2>
<p>Vos donn&eacute;es ne sont pas transmises en dehors de l&rsquo;Union Europ&eacute;enne. En cas de changement de politique de notre part, nous nous assurerons que ce transfert s&rsquo;effectue &agrave; destination des pays reconnus comme assurant un niveau ad&eacute;quat de protection de vos Donn&eacute;es ou, &agrave; tout le moins, sur la base des garanties appropri&eacute;es pr&eacute;vues par la loi.</p>
<br>
<h2><strong>1.7 Dur&eacute;e de conservation de vos donn&eacute;es</strong></h2>
<p>&nbsp;</p>
<p>Vos Donn&eacute;es sont conserv&eacute;es pour une dur&eacute;e strictement n&eacute;cessaire aux finalit&eacute;s poursuivies. La p&eacute;riode de conservation peut varier selon la finalit&eacute; des traitements.</p>
<table>
<tbody>
<tr>
<td width="313">
  <p><strong>Cat&eacute;gories de donn&eacute;es personnelles</strong></p>
</td>
<td width="313">
<p><strong>Dur&eacute;es de conservation en base active</strong></p>
</td>
</tr>
<tr>
<td width="313">
<p>Donn&eacute;es renseign&eacute;es pour participer &agrave; l&rsquo;offre</p>
<p>promotionnelle / jeu</p>
</td>
<td width="313">
<p>Un (1) an &agrave; compter de la cl&ocirc;ture de l&rsquo;offre</p>
<p>promotionnelle / jeu</p>
</td>
</tr>
<tr>
<td width="313">
<p>Donn&eacute;es des opt-in newsletters et / ou des opt-in partenaires (sous r&eacute;serve de votre consentement expr&egrave;s)</p>
</td>
<td width="313">
<p>Conservation des donn&eacute;es tant que la personne concern&eacute;e ne se d&eacute;sinscrit pas (via le lien de d&eacute;sinscription pr&eacute;vu) et tant qu&rsquo;elle demeure</p>
<p>cliente.</p>
</td>
</tr>
<tr>
<td width="313">
<p>Cookies</p>
</td>
<td width="313">
<p>Treize (13) mois maximum.</p>
<p>Cf. Politique cookies point II.</p>
</td>
</tr>
<tr>
<td width="313">
<p>Donn&eacute;es susceptibles de faire l&rsquo;objet d&rsquo;une</p>
<p>r&eacute;quisition judiciaire (donn&eacute;es de connexion, identit&eacute;, coordonn&eacute;es de contact, donn&eacute;es</p>
<p>relatives aux transactions)</p>
</td>
<td width="313">
<p>Un (1) an &agrave; compter de leur collecte</p>
</td>
</tr>
</tbody>
</table>
<p>Vos Donn&eacute;es peuvent &eacute;galement &ecirc;tre conserv&eacute;es plus longtemps pour d&rsquo;autres finalit&eacute;s, par exemple pour se conformer &agrave; nos obligations l&eacute;gales (ex. obligations comptables et fiscales). L&rsquo;acc&egrave;s aux Donn&eacute;es archiv&eacute;es est limit&eacute;.</p>
<br>
<h2><strong>1.8 S&eacute;curit&eacute; de vos donn&eacute;es</strong></h2>
<p>Nous accordons une importance particuli&egrave;re &agrave; la s&eacute;curit&eacute; des donn&eacute;es &agrave; caract&egrave;re personnel.</p>
<p>Nous avons mis en place des mesures utiles, qu&rsquo;elles soient physiques, logiques, administratives ou organisationnelles, adapt&eacute;es au degr&eacute; de sensibilit&eacute; des Donn&eacute;es, en vue d&rsquo;assurer l&rsquo;int&eacute;grit&eacute; et la confidentialit&eacute; des Donn&eacute;es et de les prot&eacute;ger contre toute intrusion malveillante, perte, alt&eacute;ration ou divulgation &agrave; des tiers non autoris&eacute;s.</p>
<p>Lorsque nous avons recours &agrave; un prestataire, nous ne lui communiquons des Donn&eacute;es qu&rsquo;apr&egrave;s avoir</p>
<p>v&eacute;rifi&eacute; le respect de ces principes de s&eacute;curit&eacute;.</p>
<p>Nous pouvons &ecirc;tre conduit &agrave; effectuer des audits afin de v&eacute;rifier la bonne application op&eacute;rationnelle des r&egrave;gles relatives &agrave; la s&eacute;curit&eacute; des Donn&eacute;es.</p>
<br>
<h2><strong>1.9 Vos droits</strong></h2>
<br>
<h3><strong>Exercice de vos droits</strong></h3>
<p>Vous pouvez nous envoyer votre demande &agrave; l&rsquo;adresse suivante, en pr&eacute;cisant le nom de l&rsquo;op&eacute;ration :</p>
<p>Service Consommateurs All&ocirc; thetiptop GALEC - BP 3000494859 - Ivry-sur-Seine</p>
<p>Si vous nous demandez ces informations sous format &eacute;lectronique, sauf contre-ordre de votre part, nous vous r&eacute;pondrons &eacute;galement par voie &eacute;lectronique.</p>
<p>Nous attirons votre attention sur le fait que votre demande est li&eacute;e &agrave; l&rsquo;adresse e-mail que vous nous communiquez pour vous identifier. Si vous avez cr&eacute;&eacute; plusieurs comptes en enregistrant plusieurs adresses e-mail diff&eacute;rentes, vous devrez nous communiquer toutes les adresses e-mail concern&eacute;es par votre demande.</p>
<p>Conform&eacute;ment &agrave; la R&egrave;glementation donn&eacute;es personnelles, votre demande sera trait&eacute;e dans les trente jours, &agrave; compter du moment o&ugrave; nous sommes en possession de tous les &eacute;l&eacute;ments n&eacute;cessaires au traitement de celle-ci. Si votre demande est complexe, ce d&eacute;lai peut &ecirc;tre prolong&eacute; de deux mois maximum, mais nous vous en informerons pr&eacute;alablement.</p>
<br>
<h3><strong>Votre demande d&rsquo;effacement de vos Donn&eacute;es</strong></h3>
<p>Les traitements sont n&eacute;cessaires &agrave; l&rsquo;ex&eacute;cution contractuelle de l&rsquo;offre promotionnelle / jeu ainsi qu&rsquo;&agrave; notre</p>
<p>int&eacute;r&ecirc;t l&eacute;gitime &agrave; en faire respecter les conditions et traiter les &eacute;ventuelles r&eacute;clamations.</p>
<p>Sous r&eacute;serve de raisons tenant &agrave; votre situation particuli&egrave;re, nous ne pourrons proc&eacute;der &agrave; l&rsquo;effacement de ces donn&eacute;es que lorsqu&rsquo;elles ne seront plus n&eacute;cessaires aux finalit&eacute;s pr&eacute;cit&eacute;es, soit un (1) an &agrave; compter de la fin de l&rsquo;op&eacute;ration, sauf si le respect d&rsquo;une obligation l&eacute;gale ou la constatation l&rsquo;exercice ou la d&eacute;fense de droits en justice impose une conservation plus longue.</p>
<br>
<h3><strong>Votre droit d&rsquo;opposition de vos Donn&eacute;es</strong></h3>
<p>Si vous avez donn&eacute; votre consentement &agrave; recevoir des offres, il s&rsquo;agit de votre droit de vous opposer &agrave; tout moment et sans justification au traitement de vos Donn&eacute;es. Toutefois nous pourrons refuser d&rsquo;acc&eacute;der &agrave; votre requ&ecirc;te en cas de motifs l&eacute;gitimes et imp&eacute;rieux.</p>
<br>
<h3><strong>Votre droit &agrave; la limitation du traitement</strong></h3>
<p>En cas de contestation relative au traitement de vos Donn&eacute;es, vous pouvez demander &agrave; ce que le traitement de vos Donn&eacute;es soit suspendu le temps de l&rsquo;instruction de la contestation. Dans ce cas, nous n&rsquo;utiliserons plus vos Donn&eacute;es tant que la contestation n&rsquo;aura pas &eacute;t&eacute; r&eacute;solue.</p>
<p>Vous pouvez exercer ce droit lors que vous vous opposez au traitement, que vous contestez l&rsquo;exactitude de vos Donn&eacute;es, que vous pensez que leur traitement est illicite ou lorsque vous en avez besoin pour la constatation, l&rsquo;exercice ou la d&eacute;fense de vos droits en justice.</p>
<br>
<h3><strong>Votre droit &agrave; la portabilit&eacute; de vos donn&eacute;es</strong></h3>
<p>Vous pouvez nous demander de transf&eacute;rer les Donn&eacute;es que vous nous avez communiqu&eacute;es &agrave; un autre responsable de traitement dans un format structur&eacute;, couramment utilis&eacute; et lisible, dans les limites pr&eacute;vues par la R&egrave;glementation donn&eacute;es personnelles. Dans ce cas, nous les transf&eacute;rerons au destinataire de votre choix.</p>
<br>
<h3><strong>Votre droit de ne pas faire l&rsquo;objet d&rsquo;une d&eacute;cision fond&eacute;e sur un traitement automatis&eacute;</strong></h3>
<p>Dans le cadre de nos activit&eacute;s, nous sommes susceptibles de mettre en place des traitements automatis&eacute;s</p>
<p>de vos Donn&eacute;es. Vous avez le droit de ne pas faire l&rsquo;objet d&rsquo;une d&eacute;cision fond&eacute;e exclusivement sur un</p>
<p>traitement automatis&eacute; produisant des effets juridiques vous concernant ou vous affectant, sauf lorsque</p>
<p>cette d&eacute;cision est n&eacute;cessaire &agrave; la conclusion ou &agrave; l&rsquo;ex&eacute;cution d&rsquo;un contrat, ou est autoris&eacute;e l&eacute;galement.</p>
<br>
<h3><strong>Votre droit d&rsquo;introduire une action aupr&egrave;s de la CNIL</strong></h3>
<p>Vous pouvez porter votre r&eacute;clamation aupr&egrave;s de l&rsquo;autorit&eacute; de contr&ocirc;le fran&ccedil;aise, la CNIL (Commission Nationale de l'Informatique et des Libert&eacute;s) en introduisant une action.</p>
<br>
<h3><strong>Votre droit de donner des directives en cas de d&eacute;c&egrave;s</strong></h3>
<p>Vous avez le droit de donner des directives sur le sort de vos Donn&eacute;es apr&egrave;s votre d&eacute;c&egrave;s.</p>
<br>
<h2><strong>1.10 D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es</strong></h2>
<p>Pour plus d&rsquo;informations sur notre politique de protection des Donn&eacute;es ou pour toute r&eacute;clamation concernant votre vie priv&eacute;e, vous pouvez contacter notre D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es (ci-apr&egrave;s &laquo; DPO &raquo;) par e-mail &agrave; l&rsquo;adresse suivante : <a href="mailto:dpo@thetiptop">dpo@thetiptop.</a></p>
<br>
<h1><strong>2. Politique Cookies</strong></h1>
<br>
<h2><strong>Comment sont g&eacute;r&eacute;s les cookies ?</strong></h2>
<p>Nous vous invitons &agrave; vous r&eacute;f&eacute;rer &agrave; la <a href="cookies">Charte des cookies </a>pr&eacute;sente sur le site.</p>
</div>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: 'Confidentialités du jeu concours Thetiptop',
    meta: [
      { name: 'Condidentialités', content: 'La page de confidentialité de notre site de jeu concours offre des informations détaillées sur nos politiques d\'utilisation du site, la confidentialité des données, la conformité légale et d\'autres aspects importants. Visitez cette page pour comprendre nos engagements, nos pratiques de collecte et d\'utilisation des données, ainsi que les règles et réglementations que nous suivons pour garantir une expérience en ligne sûre et transparente.' }
    ]
  }
}

</script>