import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const exportService = {
    exportAsExcelFile: exportAsExcelFile

}

function exportAsExcelFile(json, excelFileName, headers = null, skipHeader = false) {
    const worksheet = XLSX.utils.json_to_sheet(json, { header: headers, skipHeader: skipHeader });
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, excelFileName);
}
function saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}

export default exportService;