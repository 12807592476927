<template>
  <header class="bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 uppercase">Historiques</h1>
    </div>
  </header>
  <main>
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 text-justify text-xl ">
      <!-- Your content -->
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col"
                      class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Lot</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Numero</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Type</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Utilisateur</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tirage au sort</th>
                    <th scope="col"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tickets collectés</th>
                    <th scope="col" v-show="!$isRole('user')"
                      class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="(item, index) in store.state.ticket.data" :key="index">
                    <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{
                      item.title }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">
                      {{ item.code }}</td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{
                      item?.category?.name }}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.description }}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.user?.email }}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.draw_at }}
                    </td>
                    <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.collected_at }}
                    </td>
                    <td v-show="!$isRole('user')" class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                      <button @click="toggleDateModal(true, item.id)" title="Tick as collected"
                        class="px-2 py-1 text-red-600 rounded hover:text-white hover:bg-red-600">
                        <CheckCircleIcon class="w-4 h-4" />
                      </button>
                    </td>
                  </tr>

                  <!-- More transactions... -->
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>

    </div>
    <DateModal :show="showDateModal" :onSelect="collected" title="A été collecté" :onClose="()=>toggleDateModal(false)" />
  </main>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import store from '../store';
import { CheckCircleIcon, CheckIcon } from '@heroicons/vue/24/outline';
import DateModal from '../components/DateModal.vue';
let showDateModal = ref(false);
let setID = ref(null);
onMounted(() => {
  store.dispatch('getTickets');
})
const toggleDateModal = (value, id) => {
  console.log(value);
  setID.value = id;
  showDateModal.value = value;
}
const collected = (date) => {
  console.log("selected date:", date);
  store.dispatch('markCollected', { id: setID.value, date: date });
  toggleDateModal(false, null);
}


</script>
