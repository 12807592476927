<template>
  <header class="bg-white shadow">
    <div class="mx-auto max-w-8xl px-4 py-6 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold tracking-tight text-gray-900 uppercase">Règlement du jeu concours</h1>
    </div>
  </header>
  <main>
        <!-- images -->
        <div>
          <img src="/assets/img/img/img3.jpg" alt="image page reglement" class=" object-coover h-20 w-full object-cover">
      </div>
    <div class="mx-auto max-w-8xl py-6 sm:px-6 lg:px-8 text-1xl text-align: justify">
      
    <!-- https://thetiptop.scene7.com/is/content/gtinternet/SUE/OP/Tour%20de%20France/R%C3%A8glement%5FLEGRANDJEU%5F2023%5FDEF.pdf -->
    <p><strong>ARTICLE 1. ORGANISATION DE L&rsquo;OP&Eacute;RATION</strong><br />La soci&eacute;t&eacute; SC GALEC, Soci&eacute;t&eacute; coop&eacute;rative anonyme &agrave; Directoire et Conseil de Surveillance et &agrave; capital variable, situ&eacute;e au 26, quai Marcel Boyer, 94200 Ivry-sur-Seine, immatricul&eacute;e sous le n&deg; 642 007 991 RCS de Cr&eacute;teil (ci-apr&egrave;s la &laquo; Soci&eacute;t&eacute; Organisatrice &raquo;) organise un jeu avec obligation d&rsquo;achat du 20/06/2023 au 30/07/2023 intitul&eacute; &laquo; Le Grand Jeu &raquo; dans les modalit&eacute;s d&eacute;taill&eacute;es ci-dessous (ci-apr&egrave;s "Op&eacute;ration") :<br />- dans les points de vente thetiptop (Hyper, Super, Express ainsi que les magasins sp&eacute;cialis&eacute;s Sport thetiptop)<br />participants (ci-apr&egrave;s d&eacute;sign&eacute;s le(s) &laquo; Magasin(s) &raquo;),<br />- dans les boutiques des stations-services autoroute thetiptop (ci-apr&egrave;s d&eacute;sign&eacute;s le(s) &laquo; Boutiques des stations-services &raquo;),<br />- sur les sites Internet www.chezmoi.thetiptop, www.thetiptopdrive.fr et sur les applications mobiles thetiptopDrive&amp;<br />thetiptopChezMoi et Mon thetiptop (ci-apr&egrave;s les "Sites Internet").</p>
    <p><br />La Soci&eacute;t&eacute; Organisatrice a confi&eacute; la gestion de l&rsquo;Op&eacute;ration &agrave; la soci&eacute;t&eacute; HIGHCO SHOPPER, Soci&eacute;t&eacute; par actions simplifi&eacute;e, au capital de 228 012 Euros, dont le si&egrave;ge social est situ&eacute; au 8 rue de la Rochefoucauld - 75009 Paris, immatricul&eacute;e sous le num&eacute;ro 422 570 812 RCS PARIS.</p>
    <p><br /><strong>ARTICLE 2. DUR&Eacute;E DE L&rsquo;OP&Eacute;RATION</strong><br />L&rsquo;Op&eacute;ration Le Grand Jeu se d&eacute;roulera du mardi 20 juin 2023 &agrave; 8h00m00 au dimanche 30 juillet 2023 &agrave; 23h59m59 selon les jours et horaires d&rsquo;ouverture des Magasins et des Boutiques des stations-services participants.&nbsp; La Soci&eacute;t&eacute; Organisatrice et la soci&eacute;t&eacute; de gestion de l&rsquo;Op&eacute;ration se r&eacute;servent le droit de modifier, &eacute;courter, proroger, reporter ou annuler la pr&eacute;sente Op&eacute;ration si les circonstances l&rsquo;exigent, et ce sans pr&eacute;avis, sans que leur responsabilit&eacute; ne soit mise en cause.</p>
    <p><br /><strong>ARTICLE 3. CONDITIONS DE PARTICIPATION</strong><br />L&rsquo;Op&eacute;ration Le Grand Jeu propose un (1) jeu avec obligation d&rsquo;achat (ci-apr&egrave;s d&eacute;sign&eacute; "Jeu n&deg;1"), d&eacute;clin&eacute; sur deux (2)<br />canaux : dans les Magasins et dans les Boutiques des stations-services (canal physique) et sur les Sites Internet (canal<br />d&eacute;mat&eacute;rialis&eacute;) ainsi qu'un (1) jeu intitul&eacute; "Deuxi&egrave;me chance au tirage au sort" (ci-apr&egrave;s le "Jeu n&deg;2").<br />Les jeux sont ouverts &agrave; toute personne physique, majeure r&eacute;sidant en France m&eacute;tropolitaine, Corse, Guadeloupe et la Martinique incluses, disposant d&rsquo;une connexion internet et d&rsquo;une adresse e-mail valide, p&eacute;renne et non temporaire, &agrave; l&rsquo;exclusion des membres du personnel de la Soci&eacute;t&eacute; Organisatrice et de leur famille et d&rsquo;une fa&ccedil;on g&eacute;n&eacute;rale des soci&eacute;t&eacute;s et des personnes ayant particip&eacute; directement ou indirectement &agrave; sa promotion et/ou r&eacute;alisation (ci-apr&egrave;s les &laquo; Participants &raquo;).</p>
    <p>La participation est strictement personnelle et ne peut avoir lieu sous un ou plusieurs pseudonymes, avec plusieurs adresses e-mails, ou bien &agrave; partir d&rsquo;adresses e-mails temporaires, ou &agrave; partir d&rsquo;un compte joueur ouvert au b&eacute;n&eacute;fice d&rsquo;une autre personne qu&rsquo;elle-m&ecirc;me. Les jeux sont accessibles sur l'ensemble des navigateurs web (sur les versions des six (6) derniers mois de Microsoft Edge, Safari, Chrome et Firefox) depuis un ordinateur, une tablette et un smartphone</p>
    <p>La Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit de proc&eacute;der &agrave; toutes les v&eacute;rifications n&eacute;cessaires concernant l&rsquo;identit&eacute; et les coordonn&eacute;es des Participants.</p>
    <p><br />La participation des personnes n&rsquo;ayant pas justifi&eacute; de leurs coordonn&eacute;es et identit&eacute;s compl&egrave;tes, ou qui les auront fournies de fa&ccedil;on inexacte ou mensong&egrave;re, ne pourront &ecirc;tre valid&eacute;es, tout comme les participations des personnes<br />refusant les collectes, enregistrements et utilisations des informations &agrave; caract&egrave;re nominatif les concernant et strictement n&eacute;cessaires pour les besoins de la gestion de l&rsquo;Op&eacute;ration.</p>
    <p>Toute personne ne remplissant pas les conditions de participation sera exclue de l&rsquo;Op&eacute;ration sans pouvoir pr&eacute;tendre au b&eacute;n&eacute;fice d&rsquo;une dotation.</p>
    <p><strong>ARTICLE 4. COMMUNICATION DE L&rsquo;OP&Eacute;RATION</strong><br />La communication de l&rsquo;Op&eacute;ration Le Grand Jeu est relay&eacute;e :<br />- sur les m&eacute;dias digitaux,<br />- via des publicit&eacute;s sur les lieux de vente,<br />- sur les r&eacute;seaux sociaux,<br />- sur les prospectus,<br />- sur les sites de l&rsquo;enseigne www.thetiptopdrive.fr, www.chezmoi.thetiptop, et dans la rubrique &laquo; jeux et animations du moment &raquo; sur le sitehttps://www.thetiptop.click,<br />- sur les applications mobiles thetiptopDrive &amp; thetiptopChezMoi et Mon thetiptop,<br />- dans les newsletters,<br />- via les spots radio.</p>
    <p><br /><strong>ARTICLE 5. PRINCIPE ET MODALIT&Eacute;S DE L'OP&Eacute;RATION</strong><br />L&rsquo;Op&eacute;ration se d&eacute;roulera du 20 juin 2023 au 30 juillet 2023 selon les jours et horaires d&rsquo;ouverture des Magasins et des<br />Boutiques des stations-services autoroutes participants. Les listes des Magasins (Annexe 1), des Boutiques stations-services (Annexe 2) ainsi que des points de retrait thetiptop DRIVE et thetiptop Chez Moi participants (Annexe 3),<br />seront consultables dans la rubrique &laquo; jeux et animations du moment &raquo; sur l&rsquo;espace d&eacute;di&eacute; &agrave; l&rsquo;Op&eacute;ration sur le sitehttps://www.thetiptop.click.<br />L&rsquo;Op&eacute;ration dans les Magasins se d&eacute;roulera en deux (2) &eacute;tapes :<br />- le Jeu n&deg;1 &laquo; Cartes &agrave; gratter &agrave; r&eacute;v&eacute;lation imm&eacute;diate &raquo;<br />- le Jeu n&deg;2 &laquo; Deuxi&egrave;me chance au tirage au sort &raquo;<br />L&rsquo;Op&eacute;ration dans les Boutiques des stations-services se d&eacute;roulera en une (1) &eacute;tape, &agrave; savoir le Jeu n&deg;1 &laquo; Instants<br />gagnants &raquo;.<br />L&rsquo;Op&eacute;ration sur les Sites Internet se d&eacute;roulera en deux (2) &eacute;tapes :<br />- le Jeu n&deg;1 &laquo; Instants gagnants &raquo;<br />- le Jeu n&deg;2 &laquo; Deuxi&egrave;me chance au tirage au sort &raquo;</p>
    <p><br />5.1 Le Jeu n&deg;1 &laquo; Cartes &agrave; gratter &agrave; r&eacute;v&eacute;lation imm&eacute;diate &raquo; ou &laquo; Instants Gagnants &raquo;<br />Le Jeu n&deg;1 est r&eacute;gi par le hasard. Le Participant reconna&icirc;t express&eacute;ment qu'il participe librement au Jeu et que les achats effectu&eacute;s par ses soins ne sont pas motiv&eacute;s par l'esp&eacute;rance d'un gain pouvant r&eacute;sulter de sa participation au jeu.</p>
    <p><br />5.1.1 Le Jeu n&deg;1 &laquo; Cartes &agrave; gratter &agrave; r&eacute;v&eacute;lation imm&eacute;diate &raquo; en Magasin (hors Boutiques de stations-services). Dans les Magasins participants &agrave; l&rsquo;Op&eacute;ration Le Grand Jeu, des cartes &agrave; gratter avec r&eacute;v&eacute;lation imm&eacute;diate seront<br />remises aux consommateurs &eacute;ligibles au Jeu n&deg;1lors de leur passage en caisse.</p>
    <p>Les Participants pourront obtenir au maximum une (1) carte &agrave; gratter par passage en caisse dans les Magasins.</p>
    <p><br />La distribution des cartes &agrave; gratter d&eacute;pendra du montant et du contenu de leur panier :<br />Une (1) carte &agrave; gratter sera distribu&eacute;e :<br />- pour un montant minimum de 30&euro; d&rsquo;achat (hors livres, presse, billetterie, gaz, services et carburant),<br />ou<br />- pour l'achat d&rsquo;au moins deux (2) produits de Marques Partenaires* de l&rsquo;Op&eacute;ration en Magasins (Liste des<br />Marques Partenaires des Magasins thetiptop en Annexe 4).<br />*Les magasins Sport thetiptop ne proposant pas de produits partenaires, ce crit&egrave;re d&rsquo;&eacute;ligibilit&eacute; ne pourra donc pas &ecirc;tre pris en compte pour acc&eacute;der au jeu dans ces magasins.<br />Les Participants devront gratter la zone gris&eacute;e de la partie sup&eacute;rieure de la carte &agrave; gratter pour d&eacute;couvrir s&rsquo;ils ont gagn&eacute; une des dotations mises en jeu.<br />Sont mises en jeu dans l&rsquo;ensemble des Magasins participants, 3 998 616 dotations au total :</p>
    <p><br />Dotations Quantit&eacute;s Valeurs unitaires TTC<br />indicatives<br />Un (1) abri de plage Tour de France thetiptop 100 000 15 &euro;<br />Une (1) paire de lunettes de soleil Tour de France thetiptop 1 100 000 7 &euro;<br />Un (1) gobelet Tour de France thetiptop 2 000 016 3 &euro;<br />Un (1) guide du routard Tour de France thetiptop 798 600 15 &euro;<br />Si le participant d&eacute;couvre l&rsquo;une des mentions cit&eacute;es ci-dessus, il pourra alors r&eacute;cup&eacute;rer la dotation correspondante &agrave; l&rsquo;accueil de son magasin.</p>
    <p><br />Le gagnant devra se rendre &agrave; l&rsquo;accueil du magasin dans lequel la carte &agrave; gratter a &eacute;t&eacute; distribu&eacute;e, pour r&eacute;cup&eacute;rer sa dotation* au plus tard le lundi 14 ao&ucirc;t 2023 inclus. Pour cela, le gagnant devra fournir &agrave; l&rsquo;h&ocirc;te(sse) d&rsquo;accueil sa carte &agrave; gratter qui ne devra pas &ecirc;tre d&eacute;t&eacute;rior&eacute;e et qui devra &ecirc;tre conserv&eacute;e en une seule partie pour que cette carte soit<br />consid&eacute;r&eacute;e comme valide.</p>
    <p>*En cas d'indisponibilit&eacute; de la dotation au sein du Magasin thetiptop concern&eacute;, le gagnant pourra obtenir sa dotation en se rendant dans un autre Magasin thetiptop participant. Le gagnant pourra consulter la liste sur le site du jeu (Annexe 1 du R&egrave;glement) afin de<br />conna&icirc;tre les autres magasins thetiptop participants qui pourront disposer de sa dotation.</p>
    <p>Si le Participant d&eacute;couvre la mention &laquo; PERDU, TENTEZ VOTRE CHANCE SUR INTERNET AVEC LE CODE 2&egrave;me CHANCE &raquo;, la carte &agrave; gratter est perdante.<br />Pour chaque carte &agrave; gratter distribu&eacute;e, le Participant pourra s&rsquo;inscrire au Jeu n&deg;2 &laquo; Deuxi&egrave;me chance au tirage ausort &raquo; (Article 5.2.1).</p>
    <p><br />5.1.2 Le Jeu n&deg;1 &laquo; Instants Gagnants &raquo; dans les Boutiques des stations-services<br />Ce Jeu n&deg;1 avec obligation d&rsquo;achat fonctionne sur la base d&rsquo;Instants Gagnants al&eacute;atoires en caisse. Si le passage en caisse du consommateur correspond &agrave; un Instant Gagnant alors il gagne une des dotations mises en jeu. Les consommateurs pourront participer au Jeu n&deg;1 lors de leur passage en caisse dans les Boutiques des stations-services participantes, s'ils respectent la condition d'&eacute;ligibilit&eacute; suivante : un montant minimum de 15&euro; d&rsquo;achat (hors<br />livres, presse, billetterie, gaz, services et carburant).</p>
    <p>Les Participants peuvent donc obtenir au maximum une (1) possibilit&eacute; de jouer par passage caisse.<br />L&rsquo;h&ocirc;te(sse) de caisse remettra directement au gagnant sa dotation dans la limite des stocks disponibles.</p>
    <p><strong>ARTICLE 6. ATTRIBUTION DES DOTATIONS</strong><br />6.1 L&rsquo;attribution des dotations du Jeu n&deg;1 en Magasin et dans les Boutiques des stations-services. Les dotations ne pourront donner lieu &agrave; aucune contestation. Les dotations ne sauraient &ecirc;tre per&ccedil;ues sous une autre forme que celle pr&eacute;vue par le pr&eacute;sent r&egrave;glement.<br />En aucun cas le gagnant ne pourra demander que la dotation remise par la Soci&eacute;t&eacute; Organisatrice ne soit &eacute;chang&eacute;e contre sa valeur en esp&egrave;ces, ni fasse l&rsquo;objet d&rsquo;un &eacute;change ou d&rsquo;un remplacement contre une autre dotation.</p>
    <p><br />En cas de force majeure, la Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit de remplacer la dotation annonc&eacute;e par une autre dotation de valeur &eacute;quivalente.</p>
    <p><br />6.2 L&rsquo;attribution des dotations du Jeu n&deg;1 "Instants Gagnants" sur les Sites Internet<br />Les dotations ne pourront donner lieu &agrave; aucune contestation. Les dotations ne sauraient &ecirc;tre per&ccedil;ues sous une autre forme que celle pr&eacute;vue par le pr&eacute;sent r&egrave;glement.<br />En aucun cas le gagnant ne pourra demander que la dotation remise par la Soci&eacute;t&eacute; Organisatrice ne soit &eacute;chang&eacute;e contre sa valeur en esp&egrave;ces, ni fasse l&rsquo;objet d&rsquo;un &eacute;change ou d&rsquo;un remplacement contre une autre dotation.</p>
    <p>Dans le cadre du Jeu n&deg;1 &ldquo;Instants Gagnants&rdquo; sur les Sites Internet, la liste des attributions des dotations issues du r&eacute;sultat fera l'objet d'un proc&egrave;s-verbal dress&eacute; par huissier de justice. Cette liste pourra &ecirc;tre consultable &agrave; post&eacute;riori de l&rsquo;Op&eacute;ration sur demande &eacute;crite aupr&egrave;s de la soci&eacute;t&eacute; Organisatrice.</p>
    <p>L&rsquo;attribution des dotations est limit&eacute;e &agrave; deux (2) dotations par compte client sur toute la dur&eacute;e du Jeu n&deg;1.</p>
    <p>Conform&eacute;ment aux dispositions des articles 5.1.3, les modalit&eacute;s de r&eacute;cup&eacute;rations des dotations seront envoy&eacute;es<br />depuis l'adresse e-mail : thetiptop@gestion-promo.fr.</p>
    <p><strong>6.3 L&rsquo;attribution des dotations du Jeu n&deg;2 &laquo; tirage au sort &raquo;</strong><br />Dans la semaine suivant le tirage au sort, les gagnants seront pr&eacute;venus de leur dotation par e-mail aux coordonn&eacute;es renseign&eacute;es lors de leur inscription ou dans leur compte client thetiptop Drive ou thetiptop Chez Moi, depuis l&rsquo;adresse e-mail thetiptop@gestion-promo.fr.</p>
    <p>Pour confirmer leur souhait de b&eacute;n&eacute;ficier de leur dotation, les gagnants devront imp&eacute;rativement r&eacute;pondre &agrave; l&rsquo;e-mail de gain dans un d&eacute;lai de sept (7) jours ouvr&eacute;s &agrave; compter de la date de r&eacute;ception de l&rsquo;e-mail. A d&eacute;faut de r&eacute;ponse des gagnants dans les d&eacute;lais susmentionn&eacute;s, la dotation sera attribu&eacute;e &agrave; un suppl&eacute;ant.</p>
    <p>L&rsquo;attribution des dotations est limit&eacute;e &agrave; une (1) dotation par participant (par adresse e-mail).<br />Pour tous les gagnants ayant confirm&eacute; leur volont&eacute; de recevoir leur gain, sont stipul&eacute;es ci-apr&egrave;s les conditions de r&eacute;ception des dotations</p>
    <p><strong>ARTICLE 7. OBLIGATIONS DES PARTICIPANTS - PARTICIPATIONS NON CONFORMES</strong><br />Toute participation non conforme aux dispositions du pr&eacute;sent r&egrave;glement ne pourra &ecirc;tre prise en compte et entra&icirc;nera la nullit&eacute; de la participation et de ce fait l&rsquo;annulation de la dotation &eacute;ventuellement gagn&eacute;e. Il en sera de m&ecirc;me s&rsquo;il appara&icirc;t que des fraudes sont intervenues sous quelque forme et de quelque origine que ce soit, dans le cadre de la participation &agrave; l&rsquo;Op&eacute;ration et/ou de la d&eacute;termination des gagnants.</p>
    <p>En cas de suspicion de fraude ou de participation d&eacute;loyale, la Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit de proc&eacute;der &agrave; toute v&eacute;rification qu&rsquo;elle jugera utile et de poursuivre en justice quiconque aurait trich&eacute;, fraud&eacute;, truqu&eacute; ou troubl&eacute; le bon fonctionnement des jeux, et plus g&eacute;n&eacute;ralement de l&rsquo;Op&eacute;ration d&eacute;crite dans le pr&eacute;sent r&egrave;glement, ou aurait tent&eacute;<br />de le faire. Il est rigoureusement interdit, par quelque proc&eacute;d&eacute; que ce soit, de modifier ou de tenter de modifier la m&eacute;canique de l&rsquo;Op&eacute;ration propos&eacute;e, notamment afin d'en modifier les r&eacute;sultats ou d'influencer par un moyen d&eacute;loyal la d&eacute;signation des gagnants. En outre, la d&eacute;compilation des jeux, l&rsquo;utilisation de script personnel ou toute autre m&eacute;thode visant &agrave; contourner l&rsquo;utilisation pr&eacute;vue des Jeux dans le pr&eacute;sent r&egrave;glement sera consid&eacute;r&eacute;e &eacute;galement comme une tentative de fraude et entra&icirc;nera l&rsquo;&eacute;limination imm&eacute;diate et sans recours du Participant.</p>
    <p>Les informations et coordonn&eacute;es fournies par le Participant doivent &ecirc;tre valides et sinc&egrave;res, sous peine d'exclusion de l&rsquo;Op&eacute;ration et, le cas &eacute;ch&eacute;ant, de perte de la qualit&eacute; de gagnant.<br />Les Participants autorisent toutes les v&eacute;rifications concernant leur identit&eacute;, ou la loyaut&eacute; et la sinc&eacute;rit&eacute; de leur participation. Toute fausse d&eacute;claration ou indication d'identit&eacute; entra&icirc;ne l'&eacute;limination imm&eacute;diate du Participant et le cas &eacute;ch&eacute;ant l&rsquo;annulation de la dotation.</p>
    <p>La Soci&eacute;t&eacute; Organisatrice se r&eacute;serve le droit d&rsquo;&eacute;carter, de disqualifier ou d&rsquo;invalider le gain de toute personne ne respectant pas totalement le r&egrave;glement.<br />S'il s'av&egrave;re qu'un Participant a apparemment gagn&eacute; une dotation en contravention avec le pr&eacute;sent r&egrave;glement, par des moyens frauduleux ou par des moyens autres que ceux r&eacute;sultant du processus d&eacute;crit par la Soci&eacute;t&eacute; Organisatrice dans le pr&eacute;sent r&egrave;glement, la dotation concern&eacute;e ne lui serait pas attribu&eacute;e.</p>
    <p>La dotation resterait propri&eacute;t&eacute; de la Soci&eacute;t&eacute; Organisatrice qui se r&eacute;serve la possibilit&eacute; de la r&eacute;attribuer ou non &agrave; un autre participant, sans pr&eacute;judice des &eacute;ventuelles poursuites susceptibles d'&ecirc;tre intent&eacute;es par la Soci&eacute;t&eacute; Organisatrice ou par des tiers &agrave; l'encontre du Participant.</p>
    <p>La Soci&eacute;t&eacute; Organisatrice pourra d&eacute;cider d&rsquo;annuler l&rsquo;Op&eacute;ration s&rsquo;il appara&icirc;t que des fraudes sont intervenues sous quelque forme que ce soit.<br />La Soci&eacute;t&eacute; Organisatrice est seule d&eacute;cisionnaire de l&rsquo;exclusion des Participants concern&eacute;s au regard des informations en sa possession. En cas de sanction ou de r&eacute;clamation, il convient aux Participants d&rsquo;apporter la preuve qu&rsquo;ils ont adopt&eacute; un comportement conforme au pr&eacute;sent r&egrave;glement. La responsabilit&eacute; de la Soci&eacute;t&eacute; Organisatrice ne pourra &ecirc;tre engag&eacute;e &agrave; ce titre.</p>  
    <p><strong>ARTICLE 8. RESPONSABILIT&Eacute; DE LA SOCI&Eacute;T&Eacute; ORGANISATRICE</strong><br />La Soci&eacute;t&eacute; Organisatrice ne saurait &ecirc;tre tenue pour responsable et aucun recours ne pourra &ecirc;tre engag&eacute; contre elle en cas de faits d&rsquo;un tiers, d&rsquo;&eacute;v&eacute;nements ind&eacute;pendants de sa volont&eacute; ou de survenance d'&eacute;v&eacute;nements pr&eacute;sentant les caract&egrave;res de force majeure, telle que les cons&eacute;quences de la crise sanitaire COVID-19, ou de circonstances exceptionnelles sans que cette liste soit exhaustive, ayant pour cons&eacute;quences notamment d'emp&ecirc;cher le bon<br />d&eacute;roulement de l&rsquo;Op&eacute;ration, obligeant &agrave; modifier le jeu, privant partiellement ou totalement les gagnants du b&eacute;n&eacute;fice de leur gain, allongeant le d&eacute;lai de remise des dotations ou entra&icirc;nant la perte ou la d&eacute;t&eacute;rioration de la dotation.</p>
    <p>La participation au jeu implique la connaissance et l&rsquo;acceptation des caract&eacute;ristiques et des limites de l&rsquo;internet, notamment en ce qui concerne les performances techniques, les temps de r&eacute;ponse pour consulter, interroger ou transf&eacute;rer des informations, les risques d&rsquo;interruption, et plus g&eacute;n&eacute;ralement les risques inh&eacute;rents &agrave; toute connexion et transmission sur internet, l&rsquo;absence de protection de certaines donn&eacute;es contre des d&eacute;tournements &eacute;ventuels et les risques de contamination par des &eacute;ventuels virus circulant sur le r&eacute;seau.<br />Il est pr&eacute;cis&eacute; que la Soci&eacute;t&eacute; Organisatrice ne peut &ecirc;tre tenue responsable de tout dommage direct ou indirect issu d&rsquo;une interruption, d&rsquo;un dysfonctionnement quel qu&rsquo;il soit et ce pour quelque raison que ce soit, ou encore de tout dommage direct qui r&eacute;sulterait, d&rsquo;une fa&ccedil;on quelconque, d&rsquo;une connexion aux sites de jeu. Il appartient &agrave; tout participant de prendre toutes les mesures appropri&eacute;es de fa&ccedil;on &agrave; prot&eacute;ger ses propres donn&eacute;es et/ou logiciels<br />stock&eacute;s sur son &eacute;quipement informatique contre toute atteinte. La connexion de toute personne aux sites de jeu et la participation des participants au jeu se fait sous leur enti&egrave;re responsabilit&eacute;.</p>
    <p><br /><strong>ARTICLE 9. DROITS DE PROPRI&Eacute;T&Eacute; INTELLECTUELLE</strong><br />Conform&eacute;ment aux lois r&eacute;gissant les droits de la propri&eacute;t&eacute; intellectuelle, la reproduction et la repr&eacute;sentation de tout ou partie des &eacute;l&eacute;ments composant cette Op&eacute;ration sont strictement interdites. Toutes les marques ou noms de produits cit&eacute;s dans l&rsquo;Op&eacute;ration sont des marques d&eacute;pos&eacute;es appartenant &agrave; leur propri&eacute;taire respectif.</p>
    <p><br /><strong>ARTICLE 10. INFORMATIQUE ET LIBERT&Eacute;S</strong><br />Le Participant est inform&eacute; que les traitements li&eacute;s aux achats ouvrant droit &agrave; une participation au pr&eacute;sent jeu restent soumis &agrave; la charte de protection des donn&eacute;es ou aux conditions en mati&egrave;re de protection des donn&eacute;es des Magasins, Boutiques des stations-services, Sites internet et applications concern&eacute;s. Nous vous invitons &agrave; prendre connaissance de leurs conditions et modalit&eacute;s de traitement des donn&eacute;es personnelles aupr&egrave;s de ces entit&eacute;s. afin de connaitre les conditions et modalit&eacute;s applicables aux traitements de vos donn&eacute;es personnelles que ces tiers mettent en &oelig;uvre.</p>
    <p>La Soci&eacute;t&eacute; Organisatrice traite les donn&eacute;es &agrave; caract&egrave;re personnelles des Participants dans les conditions suivantes :<br /><strong>10.1 Responsable de traitement</strong><br />Les informations recueillies dans le cadre de la participation au jeu font l&rsquo;objet d&rsquo;un traitement de donn&eacute;es personnelles par la Soci&eacute;t&eacute; Organisatrice en qualit&eacute; de responsable de traitement.La Soci&eacute;t&eacute; Organisatrice dispose d'un D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es ("Data Protection Officer" ou DPO) qui peut &ecirc;tre contact&eacute; par mail &agrave; l'adresse dpo@thetiptop ou par courrier S.C GALEC - D&eacute;l&eacute;gu&eacute; &agrave; la Protection des Donn&eacute;es<br />- 26 quai Marcel Boyer 94200 Ivry sur Seine.<br />10.2 Finalit&eacute;s</p>
    <p><br />Les donn&eacute;es sont collect&eacute;es aux fins de :<br />1) l'organisation du jeu, l'envoi des dotations sur la base de l'ex&eacute;cution du r&egrave;glement,<br />2) la lutte contre la fraude et la gestion de litiges, sur la base de l'int&eacute;r&ecirc;t l&eacute;gitime de la Soci&eacute;t&eacute; Organisatrice,<br />3) l'envoi de prospection commerciale sur la base du consentement pr&eacute;alable du participant qu'il peut retirer &agrave; tout moment.<br />Les champs mentionn&eacute;s dans les formulaires de collecte avec un ast&eacute;risque sont obligatoires. S&rsquo;ils ne sont pas remplis,<br />la Soci&eacute;t&eacute; Organisatrice ne sera pas en mesure de donner suite &agrave; votre participation.</p> 
    <p><strong>10.4 Dur&eacute;e de conservation</strong><br />Les donn&eacute;es de participation sont conserv&eacute;es pendant une dur&eacute;e de six (6)mois &agrave; compter de la fin du Jeu. Les consentements des participants &agrave; l&rsquo;envoi de communication commerciale sont conserv&eacute;s jusqu&rsquo;au retrait du consentement ou &agrave; d&eacute;faut jusqu&rsquo;&agrave; 3 ans &agrave; compter du dernier contact du participant.</p>
    <p><br /><strong>10.5 Droits des participants</strong><br />Conform&eacute;ment &agrave; la r&eacute;glementation en vigueur, le participant dispose de droits (acc&egrave;s, rectification, effacement, opposition, limitation, portabilit&eacute;, d&eacute;finition des directives relatives &agrave; la conservation, &agrave; l'effacement et &agrave; la communication de vos donn&eacute;es personnelles apr&egrave;s le d&eacute;c&egrave;s) sur les donn&eacute;es personnelles le concernant. Il peut exercer ses droits par courrier aupr&egrave;s du Service Clients All&ocirc; thetiptop &agrave; l'adresse 26 quai Marcel Boyer - 94200 Ivry sur<br />Seine ou via le formulaire All&ocirc; thetiptop accessible ici : https://donneespersonnelles.thetiptop.<br />En cas de r&eacute;clamation, vous pouvez saisir l&rsquo;autorit&eacute; de contr&ocirc;le, la Commission nationale de l&rsquo;informatique et des libert&eacute;s (CNIL), situ&eacute;e 3 Place de Fontenoy, 75007 Paris.</p>
    <p><strong>ARTICLE 11. CONVENTION DE PREUVE</strong></p>
    <p>La Soci&eacute;t&eacute; Organisatrice a mis en place les moyens techniques n&eacute;cessaires pouvant d&eacute;montrer la participation ou la non-participation d&rsquo;un Participant. Il est donc convenu que, sauf erreur manifeste, les donn&eacute;es contenues dans les syst&egrave;mes d&rsquo;information de la Soci&eacute;t&eacute; Organisatrice ont force probante quant aux &eacute;l&eacute;ments de connexion et aux informations d&rsquo;un traitement informatique relatif &agrave; l&rsquo;Op&eacute;ration. Ainsi, sauf en cas d&rsquo;erreur manifeste, la Soci&eacute;t&eacute; Organisatrice pourra se pr&eacute;valoir, notamment aux fins de preuve de tout acte, fait ou omission, des programmes, donn&eacute;es, fichiers, enregistrements, op&eacute;rations et autres &eacute;l&eacute;ments (tels que des rapports de suivi ou autres &eacute;tats) de nature ou sous format ou support informatique ou &eacute;lectronique, &eacute;tablis, re&ccedil;us ou conserv&eacute;s directement ou indirectement par la Soci&eacute;t&eacute; Organisatrice, notamment dans ses syst&egrave;mes d&rsquo;information.</p>
    <p><br />Les Participants s'engagent &agrave; ne pas contester la recevabilit&eacute;, la validit&eacute; ou la force probante des &eacute;l&eacute;ments de nature ou sous format ou support informatique ou &eacute;lectronique pr&eacute;cit&eacute;s, sur le fondement de quelque disposition l&eacute;gale que ce soit et qui sp&eacute;cifierait que certains documents doivent &ecirc;tre &eacute;crits ou sign&eacute;s par les parties pour constituer une preuve. Ainsi les &eacute;l&eacute;ments consid&eacute;r&eacute;s constituent des preuves et, s&rsquo;ils sont produits comme moyens de preuve par la Soci&eacute;t&eacute; Organisatrice dans toute proc&eacute;dure contentieuse ou autre, ils seront recevables, valables et opposables entre les Parties de la m&ecirc;me mani&egrave;re, dans les m&ecirc;mes conditions et avec la m&ecirc;me force probante que tout document qui serait &eacute;tabli, re&ccedil;u ou conserv&eacute; par &eacute;crit.</p>
    <p><br /><strong>ARTICLE 12. R&Egrave;GLEMENT</strong><br /><strong>12.1. Acceptation du r&egrave;glement :</strong><br />Pr&eacute;alablement &agrave; toute participation &agrave; l&rsquo;Op&eacute;ration, chaque Participant doit prendre connaissance et accepter sans aucune r&eacute;serve le pr&eacute;sent r&egrave;glement et les principes de l&rsquo;Op&eacute;ration. Le fait de participer &agrave; cette Op&eacute;ration implique l&rsquo;acceptation sans r&eacute;serve du pr&eacute;sent r&egrave;glement complet ainsi que des principes et modalit&eacute;s de d&eacute;roulement de l&rsquo;Op&eacute;ration.</p>
    <p><br /><strong>12.2. Modification du r&egrave;glement :</strong><br />Toute modification apport&eacute;e &agrave; l&rsquo;Op&eacute;ration et &agrave; son r&egrave;glement fera l&rsquo;objet d&rsquo;un avenant au pr&eacute;sent r&egrave;glement. La Soci&eacute;t&eacute; Organisatrice en informera les Participants par tout moyen de son choix.</p>
    <p><br /><strong>12.3. Consultation du r&egrave;glement :</strong><br />Le r&egrave;glement complet de l&rsquo;Op&eacute;ration, y compris ses avenants &eacute;ventuels, sont d&eacute;pos&eacute;s aupr&egrave;s de l&rsquo;&eacute;tude d&rsquo;huissier : La SCP SYNERGIE HUISSIERS 13, Huissiers de Justice associ&eacute;s &agrave; 13006 MARSEILLE - 21, rue Bonnefoy. Le r&egrave;glement complet est &eacute;galement disponible &agrave; l&rsquo;accueil des points de vente participants et dans la rubrique "Jeux et Animations du moment&rdquo; sur l&rsquo;espace d&eacute;di&eacute; &agrave; l&rsquo;Op&eacute;ration sur le site <a href="http://www.thetiptop.click">www.thetiptop.click</a>.</p>
    <p><br /><strong>12.4. Contestation :</strong><br />Pour toutes questions concernant l&rsquo;Op&eacute;ration, vous pouvez vous adresser &agrave; l'adresse e-mail suivante :<br />thetiptop@gestion-promo.fr<br />Pour toute contestation ou r&eacute;clamation devra &ecirc;tre formul&eacute;e par &eacute;crit et adress&eacute;e &agrave; :<br />Service Consommateurs All&ocirc; thetiptop<br />GALEC - BP 3000494859 - Ivry-sur-Seine<br />Num&eacute;ro de t&eacute;l&eacute;phone : 09 69 32 42 52 - (Num&eacute;ro Cristal : appel non surtax&eacute;)<br />La demande devra imp&eacute;rativement comporter le nom de l&rsquo;Op&eacute;ration, la date pr&eacute;cise de participation, les coordonn&eacute;es compl&egrave;tes du participant et le motif exact de la contestation.<br />Aucun autre mode de contestation ou r&eacute;clamation ne sera pris en compte. Les contestations et r&eacute;clamations &eacute;crites ne seront plus prises en compte par la Soci&eacute;t&eacute; Organisatrice pass&eacute; un d&eacute;lai de deux (2) mois apr&egrave;s la cl&ocirc;ture de<br />l&rsquo;Op&eacute;ration</p>
    <p><strong>ARTICLE 13. LOI APPLICABLE &ndash; LITIGES &ndash; INTERPR&Eacute;TATION</strong></p>
    <p><br />Le R&egrave;glement est soumis &agrave; la loi fran&ccedil;aise.</p>
    <p><br />Toute difficult&eacute; d&rsquo;application, ou d&rsquo;interpr&eacute;tation du R&egrave;glement, ou toute question impr&eacute;vue qui viendrait &agrave; se poser dans le cadre de la participation &agrave; l&rsquo;Op&eacute;ration sera tranch&eacute;e par la Soci&eacute;t&eacute; Organisatrice.</p>
    <p><br />En cas de d&eacute;saccord persistant relatif &agrave; l&rsquo;application ou &agrave; l&rsquo;interpr&eacute;tation du pr&eacute;sent r&egrave;glement et &agrave; d&eacute;faut d&rsquo;accord amiable, le litige rel&egrave;vera des Tribunaux comp&eacute;tents</p>
  </div>
  </main>
</template>

<script>

export default {
  name: 'Reglement',
  metaInfo: {
    title: 'Reglement du jeu',
    meta: [
      { name: 'description', content: 'La page de réglementation de notre site internet fournit des informations détaillées sur nos politiques et règlements en matière d\'utilisation du site, de confidentialité des données, de conformité légale, et d\'autres aspects importants. Consultez cette page pour comprendre nos engagements envers nos utilisateurs, nos pratiques de collecte et d\'utilisation des données, ainsi que les règles et réglementations que nous suivons pour assurer une expérience en ligne sûre et transparente.' },
      { property: 'og:title', content: 'Reglement du jeu' },
    ],
    link: [
    {rel: 'canonical', href: 'https://thetiptop.click/reglement'}
  ]
  },
};

</script>