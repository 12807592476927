<template>
    <div>
        <form @submit.prevent="checkTicket" class="space-y-6">

            <label for="enter-ticket" class="block text-sm font-medium leading-6 text-gray-900">Enter Ticket #</label>
            <div class="mt-2">
                <input id="enter-ticket" name="enter-ticket" type="text" required v-model="data.code"
                    class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>

            <button type="submit"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500 sm:mt-0 sm:w-auto">
                <LoadingLabel label="Check" :loading="$store.state.ticket.loading" />
            </button>

        </form>

        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Title</th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Code</th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Category</th>
                                <!-- <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Description</th> -->
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Draw</th>
                                <th scope="col"
                                    class="uppercase whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Collected</th>

                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="(item, index) in $store.state.ticket.data" :key="index">
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{
                                    item.title }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900 uppercase">
                                    {{ item.code }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{
                                    item?.category?.name }}
                                </td>
                                <!-- <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.description }}
                                </td> -->
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.draw_at }}
                                </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{{ item.collected_at }}
                                </td>

                            </tr>

                            <!-- More transactions... -->
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import LoadingLabel from './LoadingLabel.vue';
import store from '../store';
let data = ref({});

const checkTicket = () => {
    store.dispatch('checkTicket', data.value);
}


</script>