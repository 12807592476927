<template>
  <header>
  </header>
  <main>
    <div>
      <img src="/assets/img/img/img2.jpg" alt="thetiptop" class=" object-coover h-20 w-full object-cover">
    </div>
    <form @submit.prevent="onSubmit">
      <div class="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
          <h3 class="text-5xl font-bold tracking-tight text-gray-900 uppercase">à vous de jouer</h3>
          <p class="mt-6 text-lg leading-8 text-gray-600">Merci de taper le numero à 10 chiffres se trouvant sur votre
            tickets de caisse pour découvrir votre gain.</p>
          <div>
            <label for="ticket_number" class="sr-only">Ticket</label>
            <input type="text" name="ticket_number" id="ticket" v-model="ticket_number" required minlength="10"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="N° à 10 chiffres">
          </div>
          <div class="mt-10">
            <button type="submit" :disabled="loading"
              class="block w-full rounded-md bg-[#006941] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 uppercase">
              <LoadingLabel label="Valider" :loading="loading" />
            </button>
          </div>
        </div>
      </div>
    </form>
    <CongratesModal :show="showCongrates" title="Félicitations!" :onClose="() => onClose()" :ticket="ticketTitle" />
  </main>
</template>

<script setup>
import { ref, getCurrentInstance, } from 'vue';
import apiService, { kCheckTicketApi } from '../services/apiservice';
import { useToast } from 'vue-toast-notification';
import LoadingLabel from '../components/LoadingLabel.vue';
import CongratesModal from '../components/CongratesModal.vue';

const { ctx } = getCurrentInstance();
const { $confetti } = ctx.$.appContext.app.config.globalProperties;
const $toast = useToast();

let showCongrates = ref(false);
let ticket_number = ref('');
let ticketTitle = ref('');
let loading = ref(false);


const onSubmit = async () => {
  loading.value = true;
  try {
    const res = await apiService.post(kCheckTicketApi, { code: ticket_number.value });
    console.log(res);
    if (res.status == "SUCCESS") {
      ticketTitle.value = res.data.title;
      $confetti.start();
      showCongrates.value = true;

    }

  } catch (error) {
    $toast.error(error?.message, { position: 'top' });
  }
  loading.value = false;
}

const onClose = () => {
  $confetti.stop();
  showCongrates.value = false;
}

</script>
