import apiService, { kCategoriesApi, kParticipantsApi } from '../services/apiservice';

export default {
    state: {
        data: [],
        draw: 1,
        loading: false,
        error: null
    },
    getters: {
    },
    actions: {
        async getParticipants({ commit }, draw) {
            try {
                commit('SET_PARTICIPANTS_LOADING', true);

                const res = await apiService.get(kParticipantsApi + "?draw=" + draw);
                console.log("res:", res.data);
                commit('SET_PARTICIPANTS', res.data, draw);
            } catch (error) {
                commit('SET_ERROR', error?.message);
                console.log(error);
            }
        },


    },
    mutations: {

        /* The `SET_CATEGORY_LOADING` mutation is responsible for updating the `loading` property of the `user`
        object in the state. It takes two parameters: `state` and `payload`. */
        SET_PARTICIPANTS_LOADING: (state, payload) => {
            state.loading = payload;
            state.error = null;
        },


        /* The `SET_USER` mutation is responsible for updating the `data`, `error`, and `loading`
        properties of the `user` object in the state. */
        SET_PARTICIPANTS: (state, payload, draw) => {
            state.data = payload;
            state.draw = draw;
            state.error = null;
            state.loading = false;
        },

        /* The `SET_ERROR` mutation is responsible for updating the `error` property of the `user` object in
        the state. It takes two parameters: `state` and `payload`. */
        SET_ERROR: (state, payload) => {
            state.error = payload;
            state.loading = false;
        },

        /* The `LOGOUT` mutation is responsible for clearing the user data and token in the state when
        a user logs out. It sets the `data` property of the `user` object to an empty object (`{}`)
        and sets the `token` property to `null`. This ensures that the user is logged out and their
        data is cleared from the state. */
        // LOGOUT: state => {
        //     state.user = userInitialState;
        // },
        LOGOUT: (state) => {
            state.data = [];
            state.loading = false;
            state.error = null;
        }
    },
};