
<template>
    <div class="fixed bottom-0 inset-x-0 z-[60]" v-show="isVisible">
        <!-- Card -->
        <div
            class="p-4 sm:p-6 bg-white/[.9] backdrop-blur-lg rounded-xl shadow-2xl dark:bg-slate-900/[.6] dark:shadow-black/[.7]">
            <div class="max-w-[85rem] mx-auto">
                <div class="grid lg:grid-cols-4 xl:grid-cols-5 gap-5 items-center">

                    <!-- End Col -->
                    <div class="lg:col-span-3">
                        <h2 class="text-lg font-semibold text-gray-800 dark:text-white">
                            Nous utilisons des cookies
                        </h2>
                        <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                            Nous utilisons des cookies pour personnaliser le contenu et les publicités, pour fournir des
                            fonctionnalités de médias sociaux et pour analyser notre trafic. Nous partageons également des
                            informations sur votre utilisation de notre site avec nos partenaires de médias sociaux, de
                            publicité et d'analyse qui peuvent les combiner avec d'autres informations que vous leur avez
                            fournies ou qu'ils ont collectées lors de votre utilisation de leurs services. </p>
                    </div>
                    <!-- End Col -->

                    <div class="col-span-full col-start-2 xl:col-start-5 xl:col-span-1">
                        <div class="grid sm:grid-cols-3 xl:grid-cols-1 gap-y-2 sm:gap-y-0 sm:gap-x-5 xl:gap-y-2 xl:gap-x-0">
                            <button type="button" @click="setCookie('cookie_accepted')"
                                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 bg-[#FFF] text-[#001524] hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
                                Tout accepté
                            </button>
                            <button type="button" @click="setCookie('cookie_rejected')"
                                class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-[#006941] text-white shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-gray-800 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">
                                Tout refuser
                            </button>
                        </div>
                    </div>
                    <!-- End Col -->
                </div>
            </div>
        </div>
        <!-- End Card -->

    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const isVisible = ref(true);

// Check localStorage during component initialization
onMounted(() => {
    const localStorageValue = localStorage.getItem('setCookie');
    if (localStorageValue) {
        isVisible.value = false;
    }
});

const setCookie = (cookieType) => {
    localStorage.setItem("setCookie", cookieType)
    isVisible.value = false;
};

</script>