
<template>
  <main>

    <!-- content here  -->
    <div class="flex min-h-full">
      <div class="flex flex- flex-col justify-center px-4 py-10 sm:px-6 lg:flex-none lg:px-20 xl:px-20">
        <div class="mx-auto w-full max-w-sm lg:w-90">
          <div>
            <!-- <img class="h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Thetiptop"> -->
            <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">Se connecter à votre compte</h2>
            <p class="mt-2 text-sm leading-6 text-gray-500">
              Vous n'avez pas de compte?
              <a href="register" class="font-semibold text-[#006941] hover:text-indigo-500">Enregistrer vous ici</a>
            </p>
          </div>

          <div class="mt-10">
            <div>
              <form method="POST" class="space-y-6" @submit="login">
                <div>
                  <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                  <div class="mt-2">
                    <input id="email" name="email" type="email" autocomplete="email" required v-model="user.email"
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div>
                  <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Mot de passe</label>
                  <div class="mt-2">
                    <input id="password" name="password" type="password" autocomplete="current-password" required
                      v-model="user.password"
                      class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  </div>
                </div>

                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <input id="remember-me" name="remember-me" type="checkbox"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                    <label for="remember-me" class="ml-3 block text-sm leading-6 text-gray-700">Se souuvenir de
                      moi</label>
                  </div>

                  <!-- <div class="text-sm leading-6">
                    <a href="#" class="font-semibold text-[#006941] hover:text-indigo-500">Mot de passe oublié?</a>
                  </div> -->
                </div>
                <!-- <div class="text-sm leading-6">
                  <a href="#" class="font-semibold text-[red] hover:text-indigo-500">{{ $store.state.user.error }}</a>
                </div> -->
                <div>
                  <button type="submit" :disabled="$store.state.user.loading"
                    class="flex w-full h-9 justify-center rounded-md bg-[#006941] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <LoadingLabel label="Valider" :loading="$store.state.user.loading" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="relative  hidden w-0 flex-1 lg:block">
        <img class="absolute inset-0 h-full w-screen object-cover py-10" src="/assets/img/img/thelogin2.jpg" alt="">
      </div>
    </div>
    <footer>
    </footer>
  </main>
</template>
<script setup>
import { watch, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import store from '../store';
import LoadingLabel from '../components/LoadingLabel.vue';

// début vue captcha 3
import { onMounted, onBeforeUnmount } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'

const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

const recaptchaIns = useReCaptcha().instance

onMounted(() => {
    setTimeout(() => {
        recaptchaIns.value.showBadge()
    }, 1000)
}),
onBeforeUnmount(() => {
    recaptchaIns.value.hideBadge()
}) 
// fin vue captcha 3

const router = useRouter();

watch([store.state.user], () => {
  if (Object.keys(store.state.user.data).length) {
    if (isRole('user')) {
      router.push({
        name: 'draw'
      });
    } else {

      router.push({
        name: 'dashboard'
      });
    }
  }
});

const isRole = getCurrentInstance().appContext.config.globalProperties.$isRole;

const user = {
  email: '',
  password: '',
};

function login(ev) {
  ev.preventDefault();
  console.log("user:", user);
  store
    .dispatch('login', user)

}
</script>
