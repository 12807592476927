<template>

<main>
    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div class="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
          <svg class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="0,0 90,0 50,100 0,100" />
          </svg>
  
          <div class="relative px-6 py-32 sm:py-30 lg:px-8 lg:py-0 lg:pr-0">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
              <div class="hidden sm:mb-10 sm:flex">
                <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  Concours organisé par THETIPTOP du <strong>1 au 30 Janvier 2024</strong>.
                </div>
              </div>
              <h1 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Venez fêter l'ouverture de notre 10ème boutique !</h1>
              <p class="mt-5 text-2xl leading-2 text-gray-600">Venez fêter l'ouverture de notre 10ème boutique avec Thétiptop en participant à notre jeu-concours <br> 100% gagnants !</p>
              <p class="mt-5 text-xl text-justify leading-2 text-gray-600">Pour cette occasion, nous mettons en jeu 1 500 000 tickets. Vous pouvez gagner de nombreux prix, notamment des infuseurs de thé, des boîtes de 100 g de thé détox ou d’infusion, des boîtes de 100 g de thé signature et des coffrets découverte d’une valeur de 39 ou 69 euros. En vous inscrivant et en testant votre ticket, vous participerez automatiquement au tirage au sort pour gagner un an de thé d’une valeur de 360 euros. Tous les tickets garantissent un prix. </p>
              <!-- <p class="mt-5 text-xl text-justify leading-2 text-gray-600">Pour participer, il suffit d’acheter des produits d’une valeur de 49 euros et vous recevrez un ticket contenant un numero à 10 chifres que vous pourrez utiliser sur notre site pour savoir le lots que vous avez gagné. </p>  -->
              <!-- <p class="mt-5 text-xl text-justify leading-2 text-gray-600">Le concours dure 30 jours et vous avez jusqu’à 30 jours après la date de clôture pour réclamer votre prix en ligne ou en magasin. Ne manquez pas cette occasion incroyable de gagner des prix fantastiques et de faire la fête avec nous !</p> -->
              <p class="mt-5 text-xl text-justify leading-2 text-gray-600">Inscrivez-vous dès maintenant et tentez votre chance avec votre ticket pour découvrir les superbes prix que vous gagnerez ! Il est temps de faire la fête et de savourer les thés les plus raffinés qui soient.</p>              <div class="mt-10 flex items-center gap-x-6">
                <a href="register" class="rounded-md bg-[#0c4982] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#001524] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">JE M'INSCRIS</a>
                <a href="login" class="rounded-md bg-[#006941] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#001524] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">JE ME CONNECTE</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"  src="/assets/img/img/img5.jpg" alt="image de présentations" title="image de thé">
      </div>
    </div>
  <div class=" pt-8">

  </div>

</main>

</template>

<script>
export default {
name: 'Home',
  metaInfo: {
    title: 'Page d\'acceuil du jeu',
    meta: [
      { name: 'description', content: 'La page d\'accueil de notre site internet fournit des informations détaillées concernnant le jeu concours thétiptop.' },
      { property: 'og:title', content: 'Page d\'acceuil du jeu' },
    ],
    link: [
    {rel: 'canonical', href: 'https://thetiptop.click/reglement'},
    {rel: 'canonical', href: 'https://thetiptop.click/home'},
    {rel: 'canonical', href: 'https://thetiptop.click/mentions'},
    {rel: 'canonical', href: 'https://thetiptop.click/dotations'}
  ]
  },
};


</script>