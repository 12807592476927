<script>
// import {computed} from 'vue'
// import { RouterLink, RouterView } from 'vue-router'
// import HelloWorld from './components/HelloWorld.vue'
import { mapState } from 'vuex'

// nous allons faire un export de mon object default à l'intérieur de notre balise script.
export default {
  // ajout de notre component hello world qui affiche le message you did it
  // components: {
  //   HelloWorld
  // },
  computed: {
    // à l'intérieur de map state je vais spécifier un array user pour qu'il puisse être utiliser dans notre template
    ...mapState(['user'])
   }
}

</script>

<template>  
<router-view>
  
</router-view>
</template>

<style>
header {
  line-height: 1.5;
  max-height: 100vh;
  width: 100%;

}

.logo {
  display: block;
  margin: 0 auto 2rem;
}

nav {
  width: 100%;
  font-size: 12px;
  text-align: center;
  margin-top: 2rem;
}

nav a.router-link-exact-active {
  color: var(--color-text);
}

nav a.router-link-exact-active:hover {
  background-color: transparent;
}

nav a {
  display: inline-block;
  padding: 0 1rem;
  border-left: 1px solid var(--color-border);
}

nav a:first-of-type {
  border: 0;
}

@media (min-width: 1024px) {
  header {
    display: flex;
    place-items: center;
    padding-right: calc(var(--section-gap) / 2);
  }

  .logo {
    margin: 0 2rem 0 0;
  }

  header .wrapper {
    display: flex;
    place-items: flex-start;
    flex-wrap: wrap;
  }

  nav {
    text-align: center;
    margin-left: -1rem;
    font-size: 1rem;

    padding: 1rem 0;
    margin-top: 10px;
  }
}

</style>
